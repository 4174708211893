<template>
    <div class="login-main-area">
        <div class="login-container">
            <img class="rise-logo" src="../assets/img/rise_logo_vertical_old.png">
            <div class="welcome-message">
                Welcome to the RISE Web Apps
            </div>
            <button @click="login">
                <img  src="../assets/img/btn_google_light_normal_ios.svg"> 
                <span class="login-button-text">Sign in with Google</span>
            </button>
        </div>
    </div>
</template>

<script>

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import '@/firebase/firebase.js'; // need this to initialize the FB app first

const provider = new GoogleAuthProvider();
const auth = getAuth();

export default {
    name: 'Login',
    methods: {
        login() {
            signInWithPopup(auth, provider)
            .then(result => {
                this.afterLogin(result);
            }).catch(error => {
                console.error(error);
            });
        },
        afterLogin(result) {
            this.session.processLogIn(result)
            .then(() => {
                this.ee.emit('loggedIn');
                this.$router.push('/home');
            });
        }
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/colors';

    body {
        margin: 0;
    }
    
    .login-button-text {
        padding: 10px;
        vertical-align: 100%;
    }

    .welcome-message {
        font-size: 25px;
        padding: 0 20px 40px;
    }
    .login-main-area {
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        background-color: #263C7F;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%239025a2'/%3E%3Cstop offset='1' stop-color='%239025a2' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23463e90'/%3E%3Cstop offset='1' stop-color='%23463e90' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23b33e9c'/%3E%3Cstop offset='1' stop-color='%23b33e9c' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23263C7F'/%3E%3Cstop offset='1' stop-color='%23263C7F' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23C82128'/%3E%3Cstop offset='1' stop-color='%23C82128' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23785C9C'/%3E%3Cstop offset='1' stop-color='%23785C9C' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-container {
        width: 250px;
        background-color: #f0f0f0;
        border-radius: 10px;
        height: 350px;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        .rise-logo {
            width: 130px;
        }
        button {
            &:hover {
                cursor: pointer;
            }
        }
    }

    

</style>