
export const matchingTags = (str, tags) => {
    // return list of matching tags from message which is currently being typed

    let result = [];

    if (/#\w*$/.test(str)) {
        const arr = str.split('#');
        const myStr = arr[arr.length - 1];
        result = tags.filter(element => element.substr(0, myStr.length).toLowerCase() == myStr.toLowerCase());
        result = result.map(element => element.toLowerCase()).sort();
    } 
    
    return [...new Set(result)];
};

export const addTag = (str, tag) => {
    // add hashtag to message which is currently being typed
    
    const arr = str.split('#');
    const myStr = arr[arr.length - 1];
    return str.substr(0, str.length - myStr.length - 1) + '#' + tag + ' ';
};