import { roles as allRoles } from '@/apps/roles';

export const hasPower = ({power, roles, app}) => {
                        
    if (!roles) {
        console.warn('User has no role assigned');
        return false;
    }
    if (!Array.isArray(roles)) {
        console.warn('User role field should be an array');
        return false;
    }

    const myRoles = allRoles
        .filter(role => roles.includes(role.key))
        .filter(role => role.app == app || role.app == 'all');

    const myPowers = myRoles.map(element => element.powers).flat();

    if (!myRoles.length && app) {
        console.warn('User has no recognized roles');
    }

    if (myRoles.map(role => role.key).includes('superuser')) return true;
    
    return myPowers.includes(power);
};

export const preferences = [
    {
        key: 'colorTheme',
        setFn: 'setColorTheme',
        type: 'category',
        default: 'default',
        options: [
            {
                value: 'default',
                displayName: 'Default theme'
            },
            {
                value: 'access',
                displayName: 'Accessible theme'
            },
            {
                value: 'dark',
                displayName: 'Dark theme'
            },
        ]
    },
    {
        key: 'rowWidth',
        setFn: 'setRowWidth',
        type: 'category',
        default: 'Default',
        options: [
            {
                value: 'Default',
                displayName: 'Default'
            },
            {
                value: 'Wide',
                displayName: 'Wide'
            },
            {
                value: 'Extra Wide',
                displayName: 'Extra Wide'
            },
        ]
    },
    {
        key: 'showHints',
        setFn: 'setHintsVisible',
        type: 'boolean',
        default: true,
        options: [
            {
                value: true,
                displayName: 'Show hints'
            },
            {
                value: false,
                displayName: 'Hide hints'
            }
        ]
    },
    {
        key: 'defaultSchool',
        setFn: null,
        type: 'category',
        default: '',
        options: []
    },
];

