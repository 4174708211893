import { reactive } from 'vue';
import { installFB } from '@/firebase/firebase.js';
import { installPlugins } from '@/core/plugins.js';
import { installSession } from '@/core/session.js';
import { preferences } from '@/core/user.js';
import themes from '@/assets/styles/colors.scss';
import { isEnrolled } from '@/functions/utils.js';
import { routes } from '@/router/index.js';

export const store = {
    install: (app, options) => {

        app.config.globalProperties.config = {};

        installPlugins(app, options);

        installFB(app, options);

        installSession(app, options);
        
        const getInitialState = () => ({
            hasLaunched: false,
            currentApp: '',
            chosenSchool: '',
            studentData: [],
            customProp: null, // used for passing data between sibling components
            routes: routes,
            // users: [],
            notes: [],
            tags: [],
            // events: [],
            showingGradeLevel: null,
            ui: {
                sidebarVisible: true,
                chatVisible: false,
                themeRules: themes,
                loadedThemes: preferences.find(e => e.key == 'colorTheme').options,
                currentColorTheme: 'default',
                colors: {},
                rowWidth: 'Default',
                showHints: true
            }
        });

        app.config.globalProperties.store = {
            debug: false,
            state: reactive(getInitialState()),
            setUserPreferences() {
                const userPreferences = app.config.globalProperties.$user.preferences;
                preferences.forEach(element => {
                    if (userPreferences[element.key] !== undefined && element.setFn) {
                        this[element.setFn](userPreferences[element.key]);
                    }
                });
            },
            info(...args) {
                if (this.debug) console.info(...args);
            },
            emit(...args) {
                app.config.globalProperties.ee.emit(...args);
            },
            clear() {
                this.info('clear store triggered');

                this.state.studentData = [];
                this.state.notes = [];
                this.state.tags = [];
                this.state.customProp = null;
            },
            resetUI() {
                this.info('reset UI triggered');

                this.setColorTheme('default');
                this.setRowWidth('Default');
                this.setHintsVisible(true);
                this.setSidebarVisible(true);
                this.setChatVisible(false);
            },
            setColorTheme(newValue) {
                this.info('setColorTheme triggered with', newValue);
                this.state.ui.currentColorTheme = newValue;
                const themeNameLength = newValue.length;
                
                for (const [key, value] of Object.entries(this.state.ui.themeRules)) {
                    if (key.substring(0,themeNameLength) == newValue) {
                        this.state.ui.colors[key.substring(themeNameLength)] = value;
                    }
                }

                document.body.setAttribute('data-theme', newValue);
                this.emit('colorThemeChanged');
            },
            setRowWidth(newValue) {
                this.info('setRowWidth triggered with', newValue);
                this.state.ui.rowWidth = newValue;
                this.emit('rowWidthChanged');
            },
            setLaunched(newValue) {
                this.info('setLaunched triggered with', newValue);
                this.state.hasLaunched = newValue;
            },
            setApp(newValue) {
                this.info('setApp triggered with', newValue);
                this.state.currentApp = newValue;
            },
            setHintsVisible(newValue) {
                this.info('setRowWidth triggered with', newValue);
                this.state.ui.showHints = newValue;
            },
            toggleSidebarVisible() {
                this.info('toggleSidebarVisible triggered');
                this.setSidebarVisible(!this.state.ui.sidebarVisible);
            },
            setSidebarVisible(newValue) {
                this.info('setSidebarVisible triggered with', newValue);
                this.state.ui.sidebarVisible = newValue;
                this.emit('sidebarToggled', newValue);
            },
            toggleChatVisible() {
                this.info('toggleChatVisible triggered');
                this.setChatVisible(!this.state.ui.chatVisible);
            },
            setChatVisible(newValue) {
                this.info('setChatVisible triggered with', newValue);
                this.state.ui.chatVisible = newValue;
                this.emit('chatToggled', newValue);
            },
            setGradeLevel(newValue) {
                this.info('setGradeLevel triggered with', newValue);
                this.state.showingGradeLevel = newValue;
                this.emit('gradeLevelChange', newValue);
            },
            setSchool(newValue) {
                this.info('setSchool triggered with', newValue);
                this.state.chosenSchool = newValue;
                this.emit('schoolChanged', newValue);
            },
            setNotes(newValue) {
                this.info('setNotes triggered with', newValue);
                this.state.notes = newValue;
                this.emit('noteDataReceived');
            },
            setTags(newValue) {
                this.info('setTags triggered with', newValue);
                this.state.tags = newValue.sort((a, b) => a.title > b.title ? 1 : -1);
                this.emit('tagDataReceived');
            },
            setStudentData(newValue) {
                this.info('setStudentData triggered with', newValue);

                const myData = this.applyMutators(newValue);

                this.state.studentData = myData
                    .filter(record => isEnrolled(record))
                    .sort((a, b) => a.lastName > b.lastName ? 1 : -1);
                
                this.emit('studentDataReceived');
            },
            applyMutators(data) {
                app.config.globalProperties.config.fields.forEach(field => {
                    if (field.mutator) {
                        data.forEach(record => {
                            record[field.key] = field.mutator(null, record);
                        });
                    }
                });

                return data;
            },
            setCustomProp(newValue) {
                this.info('setCustomProp triggered with', newValue);
                this.state.customProp = newValue;
            },
            // setEvents(newValue) {
            //     this.info('setEvents triggered with', newValue);
            //     this.state.events = newValue;
            //     this.emit('eventDataReceived');
            // },
            // setUsers(newValue) {
            //     this.info('setUsers triggered with', newValue);
            //     this.state.users = newValue.sort((a, b) => a.lastName > b.lastName ? 1 : -1);;
            // },
        };

        app.config.globalProperties.store.setColorTheme('default');
    }
};
