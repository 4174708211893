<template>
  <button class="custom-button">{{ buttonText }}</button>
</template>

<script>
export default {
    props: {
        buttonText: String,
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/colors.scss';

    .custom-button {
        background-color: var(--color-primary);
        &:hover {
            background-color: var(--color-primary-light);
        }
        font-family: 'Lato', sans-serif;
        border-radius: 4px;
        border: none;
        color: var(--color-bg);
        margin: 7px 0;
        padding: 8px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        transition-duration: 0.3s;
        cursor: pointer;
    }

    .btn-warning {
        background-color: var(--color-warning);
        &:hover {
            background-color: var(--color-warning-light);
        }
    }

    .btn-success {
        background-color: var(--color-success);
        &:hover {
            background-color: var(--color-success-light);
        }
    }

</style>