/* eslint no-unused-vars: "off" */
import { formatters } from '@/functions/formatters.js';
import studentSchema from '@/schemas/studentSchema.json';
import { processFieldsAndGroups, getFirst, mySort, customHeaderFunc } from '@/functions/utils';

const stepOptions = {
    'not started': 'Not started',
    'in progress': 'In progress',
    'completed': 'Completed'
};

const stepOrder = ['Not started', 'In progress', 'Completed'];

let _fields = [
    {
        key: '_studentName', // keys beginning with underscore indicate keys that do not exist in underlying data
        displayName: 'Student Name',
        userDescription: 'Student first and last names pull from PowerSchool.',
        type: 'unique',
        logField: false,
        width: 150,
        frozen: true,
        headerFilter: true,
        readOnly: true,
        hozAlign: 'left',
        mutator: function (value, data, type, params, component) {
            return [data.lastName, data.firstName].join(', ');
        },
    },
    {
        key: '_notes',
        width: 40,
        type: 'notes',
        hideInFilters: true,
        hideInStudentProfile: true,
        logField: false,
        userDescription: 'See notes about this student',
        readOnly: true,
        headerSort: false,
    },
    {
        key: 'tags',
        displayName: 'Tags',
        userDescription: 'Opens the tags editor which allows the user to add or remove tags to a student.',
        type: 'tags',
        hideInFilters: true,
        hideInStudentProfile: true,
        logField: true,
        width: 180,
        readOnly: false,
        headerFilter: 'input',
        headerSort: false,
        sorter: (a, b) => {

            if (!a[0]) return 1;
            if (!b[0]) return -1;

            const A = a[0].value.join(' ');
            const B = b[0].value.join(' ');

            if (A < B) return -1;
            if (A > B) return 1;
        
            return 0;
        }
    },
    {
        key: 'studentDistrictId',
        displayName: 'Student ID',
        userDescription: 'Student identification numbers pull from PowerSchool.',
        headerFilter: true,
        type: 'unique',
        logField: false,
        readOnly: true,
    },
    {
        key: 'email',
        displayName: 'Student Email',
        userDescription: 'Student emails pull from the student survey.',
        type: 'unique',
        readOnly: false,
        formatter: (cell, victory, colors) => getFirst(cell),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'counselorName',
        displayName: 'Counselor',
        userDescription: 'Each student in the tracker is connected to one counselor. This information pulls directly from PowerSchool; therefore, it is critically important for schools to maintain accurate caseload assignments in PowerSchool.',
        type: 'category',
        logField: false,
        readOnly: true,
        headerFilter: true,
    },
    {
        key: 'currentGradeLevel',
        displayName: 'Current Grade',
        userDescription: 'Current grade level of student.',
        readOnly: true,
        logField: false,
        type: 'numeric'
    },
    {
        key: 'gender',
        displayName: 'Gender',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'category',
        readOnly: true,
        logField: false,
        headerFilter: 'select', 
        headerFilterFunc: customHeaderFunc,
        headerFilterParams: {
            values: {
                'Male': 'Male',
                'Female': 'Female',
                '' : 'All'
            }
        },
    },
    {
        key: 'raceAndEthnicity.race',
        displayName: 'Race',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'category',
        logField: false,
        readOnly: true,
        headerFilter: true
    },
    {
        key: 'englishLearner',
        displayName: 'English Learner',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'boolean',
        logField: false,
        options: {
            true: 'ELL',
            false: 'Not ELL'
        },
        readOnly: true,
        formatter: (cell, victory, colors) => cell.getValue() ? 'ELL' : 'Not ELL'
    },
    {
        key: 'specialEducation',
        displayName: 'Special Ed',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'boolean',
        logField: false,
        options: {
            true: 'Sped',
            false: 'Not Sped'
        },
        readOnly: true,
        formatter: (cell, victory, colors) => cell.getValue() ? 'Sped' : 'Not Sped'
    },
    {
        key: 'firstGeneration',
        displayName: 'First Generation',
        userDescription: 'This status pulls from the student survey.',
        type: 'boolean',
        logField: false,
        options: {
            true: 'First Generation',
            false: 'Not First Generation'
        },
        readOnly: false,
        formatter: (cell, victory, colors) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'school.district',
        displayName: 'District',
        userDescription: 'The student’s school district.',
        readOnly: true,
        type: 'category',
        logField: false,
    },
    {
        key: 'creditsEarned',
        displayName: 'Total Credits',
        userDescription: 'This variable shows the credits the student has already earned.',
        readOnly: true,
        type: 'numeric',
        logField: false,
    },
    {
        key: 'school.name',
        displayName: 'School',
        userDescription: 'The student’s high school.',
        readOnly: true,
        type: 'category',
        logField: false,
    },
    {
        displayName: 'Active Plan', 
        userDescription: 'This field automatically populates with a student’s postsecondary plan. The plan appears in green if a student has confirmed one plan after high school; it appears in grey if a student has not identified a plan.',
        key: 'plans',
        type: 'category',
        width: 160,
        options: {
            'No Plan' : 'No Plan',
            '4-Year College': '4-Year College',
            '2-Year College': '2-Year College',
            'Trade or Technical': 'Trade or Technical',
            'Military': 'Military',
            'Alternative Plan': 'Alternative Plan',
            'Workforce': 'Workforce',
            'Unsure': 'Unsure'
        },
        order: ['4-Year College', '2-Year College', 'Trade or Technical', 'Military', 'Workforce', 'Alternative Plan', 'Unsure', 'No Plan'],
        headerFilter: true,
        headerFilterFunc: function (headerValue, rowValue, rowData, filterParams) {
            if (!rowValue[0]) return false;
            if (!rowValue[0].value) return false;
            return rowValue[0].value.toLowerCase().includes(headerValue.toLowerCase());
        },
        readOnly: false,
        victory: value => value !== 'No Plan',
        formatter: (cell, victory, colors) => {
            let value = getFirst(cell);
            return formatters.badgify(value, victory(value), colors);
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'alternatePlans',
        displayName: 'Alternate Plans',
        userDescription: 'Student\'s possible alternate secondary plans',
        width: 160,
        headerFilter: true,
        headerFilterFunc: function (headerValue, rowValue, rowData, filterParams) {
            if (!rowValue[0]) return false;
            if (!rowValue[0].value) return false;
            return rowValue[0].value.toLowerCase().includes(headerValue.toLowerCase());
        },
        options: {
            'No Plan' : 'No Plan',
            '4-Year College': '4-Year College',
            '2-Year College': '2-Year College',
            'Trade or Technical': 'Trade or Technical',
            'Military': 'Military',
            'Alternative Plan': 'Alternative Plan',
            'Workforce': 'Workforce',
            'Unsure': 'Unsure'
        },
        type: 'categoryArray',
        readOnly: false,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return typeof value == 'string' ?
                value.replace(',', ', '):
                value
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: '_predictedCredits',
        displayName: 'Predicted EOY Credits',
        userDescription: 'This variable shows students total earned credits plus predicted credits for the current school year. The variable assumes students will earn credit for active courses where students are currently earning passing grades of 60 or higher (Naugatuck requires >70).',
        type: 'numeric',
        readOnly: true,
        mutator: function (value, data, type, params, component) {
            if (!data.creditsEarned) return null;
            if (!data.creditsPredicted) return null;

            return +(data.creditsEarned + data.creditsPredicted).toFixed(1);
        },
        logField: false,
    },
    {        
        key: 'graduationConcerns',
        displayName: 'Graduation Concerns',
        userDescription: "This field allows counselors to flag students with graduation concerns (e.g., concerns, no concerns). The tracker provides information about students’ predicted end-of-year credits, and counselors should take time to review students' credits and content/course-specific credit requirements. Please flag students with graduation concerns to then pursue targeted interventions and scheduling changes.",
        type: 'boolean',
        readOnly: false,
        options: {
            true: 'Concerns',
            false: 'No Concerns'
        },
        victory: value => !value,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            if (value === null) return formatters.nullWarning(colors);
            return formatters.warnify(value == true, colors);
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {        
        key: '_GPABand',
        displayName: 'GPA Band',
        userDescription: "This variable shows each student’s cumulative GPA for final grades. It excludes active courses; transfer course grades are included if those grades have been properly entered into PowerSchool. Students are grouped into GPA bands in .5 increments (e.g., 0.0-0.5, 0.5-1.0). You may hover over a student’s GPA band to see their precise GPA. The GPA bands allow counselors to filter for students in GPA groupings. GPAs appear in green (vs. grey) if students have a college readiness GPA greater than 3.0.",
        type: 'category',
        readOnly: true,
        order: [
            '0.0-0.5', '0.5-1.0', '1.0-1.5', '1.5-2.0',
            '2.0-2.5', '2.5-3.0', '3.0-3.5', '3.5-4.0', '4.0+',
        ],
        victory: value => ['3.0-3.5', '3.5-4.0', '4.0+'].filter(element => element == value).length > 0,
        mutator: function (value, data, type, params, component) {
            if (data.GPA == null) return null;
            if (data.GPA >= 4) return '4.0+';

            const myGPA = Array(8)
                .fill()
                .map((x,i) => ({
                    highestBound: (i/2+0.5),
                    display: `${(i/2).toFixed(1)}-${(i/2+0.5).toFixed(1)}`
                })).find(element => element.highestBound > data.GPA)
            
            return myGPA?.display || null;
        },
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        },
        logField: false,
    },
    {
        key: 'SATScores.EBRW',
        displayName: 'SAT EBRW',
        userDescription: 'The SAT EBRW score reflects each student’s highest score on the EBRW section of the SAT exam. The score appears in green (vs. grey) if the student meets or exceeds the College Board’s threshold for college readiness on that section of the SAT.',
        type: 'numeric',
        readOnly: true,
        victory: value => value >= 500,
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        },
        logField: false,
    },
    {
        key: 'SATScores.Math',
        displayName: 'SAT Math',
        userDescription: 'The SAT Math score reflects each student’s highest score on the Math section of the SAT exam. The score appears in green (vs. grey) if the student meets or exceeds the College Board’s threshold for college readiness on that section of the SAT.',
        type: 'numeric',
        readOnly: true,
        victory: value => value >= 540,
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        },
        logField: false,
    },
    {
        key: '_ADA', 
        displayName: 'ADA This Year',
        userDescription: "The average daily attendance field reflects each student’s attendance rate so far during the current school year. This focus is a percent; the denominator reflects a student’s enrollment days at the school during the current year, and the numerator reflects a student’s number of days present.",
        type: 'numeric',
        logField: false,
        readOnly: true,
        victory: value => value >= 90,
        mutator: function (value, data, type, params, component) {
            const raw = data.ADA;
            return Number.isFinite(raw) ? 
                +(raw * 100).toFixed() : 
                null;
        },
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        }
    },
    {
        displayName: '4-Year College Steps Done',
        userDescription: 'How many steps the student has completed towards applying to 4-year college.',
        key: '_4YearStepsDone',
        type: 'numeric',
        logField: false,
        readOnly: true,
        mutator: function (value, data, type, params, component) {
            if ([
                'navianceCollegeList',
                'FAFSAStatus',
                'collegeApps',
                'studentRequestedRecs',
                'teacherSubmittedRecs',
            ].filter(element => data[element] == undefined).length) {
                console.error('missing data. Cannot calculate 4-year college steps done.');
                return 0;
            }

            return [
                'navianceCollegeList',
                'FAFSAStatus',
                'collegeApps',
                'studentRequestedRecs',
                'teacherSubmittedRecs',
            ].filter(element => data[element][0].value == 'completed').length;
        },
        formatter: function (cell, victory, colors) {
            return cell.getValue().toString() + '/5';
        }
    },
    {
        key: 'intendedMajor',
        displayName: 'Intended Major',
        userDescription: "This variable reflects a student’s intended major if students have identified an intended major through the student survey or counselor data entry.",
        type: 'category',
        readOnly: false,
        formatter: (cell, victory, colors) => getFirst(cell),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'summerCollegePrepInterest',
        displayName: 'Summer Prep Interest',
        userDescription: 'Student is interested in attending Summer Prep Academy.',
        type: 'boolean',
        options: {
            true: 'Plans to do summer prep',
            false: 'No plans to do summer prep'
        },
        readOnly: false,
        formatter: (cell, victory, colors) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'FAFSAIntention',
        displayName: 'FAFSA Intention',
        userDescription: 'Student intends to complete the FAFSA.',
        type: 'boolean',
        options: {
            true: 'Plans to complete FAFSA',
            false: 'No plans to complete FAFSA'
        },
        readOnly: false,
        formatter: (cell, victory, colors) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'noFAFSAIntentionExplanation',
        displayName: 'No FAFSA Explanation',
        userDescription: 'Student\'s reason for planning not to complete the FAFSA.',
        type: 'unique',
        readOnly: false,
        formatter: (cell, victory, colors) => getFirst(cell),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'collegeCommitment',
        displayName: 'Committed College',
        userDescription: 'This lists the college that the student plans to attend, after being accepted.',
        type: 'category',
        readOnly: true,
        victory: value => !!value && (value !== 'No commitment'),
        formatter: (cell, victory, colors) => {
            let value = getFirst(cell);
            if (value == '') value = 'No commitment'
            return formatters.badgify(value, victory(value), colors);
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: '_collegeAppsSubmitted',
        displayName: '# of College Apps',
        userDescription: "This variable shows the number of applications submitted by each student. This field reports students in one of four bands: 0, 1-5, 6-10, or 11+ applications. These bands allow counselors to filter for students needing application support.",
        type: 'category',
        logField: false,
        readOnly: true,
        options: {
            '0' : '0',
            '1-5': '1-5',
            '6-10': '6-10',
            '11+': '11+',
        },
        order: ['0', '1-5', '6-10', '11+'],
        mutator: function (value, data, type, params, component) {
            if (data.collegeAppsSubmitted == null) return null;

            let result = [
                {lowestBound: 0, highestBound: 0, band: '0'},
                {lowestBound: 1, highestBound: 5, band: '1-5'},
                {lowestBound: 6, highestBound: 10, band: '6-10'},
                {lowestBound: 11, highestBound: 1000, band: '11+'},
            ].find(element => 
                (element.lowestBound <= data.collegeAppsSubmitted[0].value) && 
                (element.highestBound >= data.collegeAppsSubmitted[0].value)
            );

            return result.band;
        },
        victory: value => ['6-10', '11+'].filter(element => element == value).length > 0,
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        }
    },
    {
        key: 'careerInventory',
        displayName: 'Career Inventory',
        userDescription: 'Counselors may update this field to show whether students have completed a career inventory.',
        type: 'category',
        readOnly: false,
        sorter: (a, b) => mySort(a, b),
        logField: true,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'navianceCollegeList',
        displayName: 'Naviance College List',
        userDescription: 'This field shows whether students have entered a college list in Naviance.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'FAFSAStatus',
        displayName: 'FAFSA',
        userDescription: 'This variable shows where students are in the process of completing the FAFSA. “Incomplete” means that students submitted the FAFSA but submitted an incomplete application.',
        type: 'category',
        readOnly: false,
        options: {
            'not started': 'Not started',
            'in progress': 'In progress',
            'completed': 'Completed',
            'N/A': 'N/A'
        },
        order: ['Not started', 'In progress', 'Completed', 'N/A'],
        victory: value => ['completed', 'N/A'].includes(value),
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'FAFSAStateStatus',
        displayName: 'EdSight Data',
        userDescription: "This shows the student’s FAFSA status, according to the CT State Department of Education.",
        type: 'category',
        readOnly: true,
        options: {
            'Not Submitted': 'Not Submitted',
            'Incomplete': 'Incomplete',
            'Complete': 'Complete'
        },
        order: ['Not Submitted', 'Incomplete', 'Complete'],
        victory: value => value == 'Complete',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            return getFirst(cell);
        }
    },
    {
        key: 'FSAId',
        displayName: 'FSA ID',
        userDescription: "The student's Federal Student Aid ID number.",
        type: 'unique',
        readOnly: false,
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            return getFirst(cell);
        }
    },
    {
        key: 'collegeApps',
        displayName: 'College Apps',
        userDescription: 'This variable shows whether or not students have submitted college applications.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'studentRequestedRecs',
        displayName: 'Student Requested Recs',
        userDescription: 'This field shows whether students have requested letters of recommendation.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'teacherSubmittedRecs',
        displayName: 'Teachers Submitted Recs',
        userDescription: 'This field shows whether teachers have completed letters of recommendation.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'transcriptsSent',
        displayName: '# Transcripts Sent',
        userDescription: 'This field shows the number of transcripts submitted to colleges and/or universities for each student through Naviance.',
        type: 'numeric',
        readOnly: true,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => getFirst(cell)
    },
    {
        key: 'sentTranscripts',
        displayName: 'Transcripts Sent (Trade)',
        userDescription: 'Counselors may update this field to indicate whether transcripts were sent to trade/technical programs.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => getFirst(cell)
    },
    {
        key: 'immunizationForm',
        displayName: 'Immunization Form',
        userDescription: "This variable shows students’ status completing and submitting paperwork around required immunizations.",
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'ASVAB',
        displayName: 'ASVAB Exam',
        userDescription: 'Counselors may update this field to show whether students have completed the ASVAB exam necessary to enlist in the military.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'militaryRecruiter',
        displayName: 'Military Recruiter',
        userDescription: 'Counselors may update this field to indicate whether students have met with a military recruiter.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'militaryBranchCommitment',
        displayName: 'Military Branch Commitment',
        userDescription: "Counselors may update this field to show whether students have committed to a specific brand of the military (i.e., Air Force, Army, Coast Guard, Navy, and Space Force).",
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'militaryBranchInterest',
        displayName: 'Military Branch',
        userDescription: 'Military branch in which the student plans to enlist',
        type: 'category',
        readOnly: false,
        options: {
            'Army': 'Army',
            'Navy': 'Navy',
            'Air Force': 'Air Force',
            'Marines': 'Marines',
            'Coast Guard': 'Coast Guard',
            'Army National Guard': 'Army National Guard',
            'Air National Guard': 'Air National Guard',
            'Space Force': 'Space Force',
            'None': 'None'
        },
        order: ['Army', 'Navy', 'Air Force', 'Marines', 'Coast Guard', 'Army National Guard', 'Air National Guard', 'Space Force', 'None'],
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => getFirst(cell),
    },
    {
        key: 'workPermit',
        displayName: 'Work Permit',
        userDescription: 'Counselors may update this field to show whether students have obtained a work permit.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'resume',
        displayName: 'Resume',
        userDescription: 'Counselors may update this field to show whether students have developed a resume.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'jobApplications',
        displayName: 'Job Applications',
        userDescription: 'Counselors may update this field to show whether students have submitted applications for employment.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'employment',
        displayName: 'Employment',
        userDescription: 'Counselors may update this field to show whether students have confirmed plans for postsecondary employment.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'tradeProgramEnrollment',
        displayName: 'Trade Program Enrollment',
        userDescription: 'Counselors may update this field to show whether students have enrolled in a trade or technical program.',
        type: 'category',
        readOnly: false,
        options: stepOptions,
        order: stepOrder,
        victory: value => value == 'completed',
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.stepify(value, colors);
        }
    },
    {
        key: 'postSecondaryRegistration',
        displayName: 'Registration',
        userDescription: 'Registration for 2-year 4-year, Trade School and Military programs.',
        type: 'category',
        readOnly: false,
        options: {
            true: 'Registered',
            false: 'Not registered',
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.checkify(value, colors);
        }
    },
    {
        key: 'postSecondaryOrientation',
        displayName: 'Orientation',
        userDescription: 'Orientation for 2-year 4-year, and Trade School programs.',
        type: 'category',
        readOnly: false,
        options: {
            true: 'Oriented',
            false: 'Not Oriented',
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            return formatters.checkify(value, colors);
        },
    },
    {
        key: 'lastNavianceLogin',
        displayName: 'Naviance Login',
        userDescription: 'This column shows the date when students last logged into the Naviance platform.',
        readOnly: true,
        type: 'date',
        logField: false,
    },
];

// check read only fields match correctly
_fields.forEach(f => {
    if (f.readOnly == undefined) {
        console.error('field is missing readonly property', f);
        return;
    }
    if (!f.key) return;
    const myField = studentSchema.properties[f.key]
    if (!myField) return;
    if (myField.readOnly == undefined) {
        console.error('field is missing readonly property in schema', myField);
        return;
    }
    if (myField.readOnly !== f.readOnly) {
        console.error('read only properties do not match between schema and fields.js', f);
    }
});

let groups = [
    {
      name: 'Student Details',
      fields: [
        '_studentName',
        'studentDistrictId',
        'currentGradeLevel',
        'email',
        'counselorName',
        'tags',
        'school.name',
        'school.district',
        'gender',
        'raceAndEthnicity.race',
        'englishLearner',
        'specialEducation',
        'firstGeneration'
      ]
    },
    {
      name: 'Plans',
      fields: [
        'plans',
        'alternatePlans',
        'collegeCommitment'
      ]
    },
    {
      name: 'HS Academics',
      fields: [
        '_ADA',
        '_GPABand',
        'graduationConcerns',
        'lastNavianceLogin',
        '_predictedCredits',
        'SATScores.EBRW',
        'SATScores.Math',
        'creditsEarned'
      ]
    },
    {
      name: 'College Tasks',
      fields: [
        'transcriptsSent',
        '_collegeAppsSubmitted',
        '_4YearStepsDone',
        'collegeApps',
        'summerCollegePrepInterest',
        'FAFSAIntention',
        'noFAFSAIntentionExplanation',
        'FAFSAStatus',
        'FAFSAStateStatus',
        'FSAId',
        'immunizationForm',
        'intendedMajor',
        'navianceCollegeList',
        'studentRequestedRecs',
        'teacherSubmittedRecs',
        'postSecondaryRegistration',
        'postSecondaryOrientation'
      ]
    },
    {
      name: 'Workforce/Military Tasks',
      fields: [
        'ASVAB',
        'careerInventory',
        'employment',
        'jobApplications',
        'militaryBranchCommitment',
        'militaryRecruiter',
        'militaryBranchInterest',
        'resume',
        'sentTranscripts',
        'tradeProgramEnrollment',
        'workPermit'
      ]
    }
];

export const [fields, fieldGroups] = processFieldsAndGroups(_fields, groups);

