<template>
    <div class="message-content">
        <span 
            v-for="(snippet, index) in snippets" :key="index" 
            :class="snippet.style" 
            @click="clickOn(snippet)"
        >
            {{ snippet.content }}
        </span>
        <div class="studentNameInMessage" v-if="shouldShowStudent()">{{ studentName }}</div>
    </div>
</template>

<script>
export default {
    name: 'Message',
    props: {
        content: String,
        studentID: {
            type: String,
            default: ''
        },
        studentFocus: Object,
    },
    methods: {
        clickOn(snippet) {
            if (snippet.style == 'hashtag') {
                this.$emit('clickOn', snippet.content);
            }
        },
        getStudentName(studentData, ID) {
            const myRecord = studentData.find(record => record.studentRISEId == ID);
            if (!myRecord) return ''
            return [myRecord.firstName, myRecord.lastName].join(' ');
        },
        shouldShowStudent() {
            // should this message show student name in message body
            if (!this.studentName) return false; // if the message has no associated student
            if (!this.studentFocus.name) return true; // if the chat app has no student focus
            return false;
        },
        getSnippets(content) {
            return content.split(/(#\w*)/)
                .map(element => element.includes('#') ?
                    {content: element, style: 'hashtag'} :
                    {content: element, style: ''}
                )
        }
    },
    computed: {
        studentName: function () {
            return this.getStudentName(this.store.state.studentData, this.studentID);
        },
        snippets: function () {
            return this.getSnippets(this.content);
        }
    },
}
</script>

<style lang="scss">
@import '../assets/styles/colors';

    .message-content {
        border-radius: 5px;
        background-color: var(--color-bg);
        padding: 5px;
    }

    .hashtag {
        color:var(--color-warning);
        &:hover {
            cursor: pointer;
            color: var(--color-warning-light);
        }
    }

    .studentNameInMessage {
        color:var(--color-contrast-high);
        padding-top: 0.6rem;
        text-align: right;
    }

</style>