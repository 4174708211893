/* eslint no-unused-vars: "off" */
import { formatters } from '@/functions/formatters.js';
import studentSchema from '@/schemas/studentSchema.json';
import { coursesToSVG } from '@/functions/coursesSVG';
import { processFieldsAndGroups, getFirst, mySort, customHeaderFunc } from '@/functions/utils';

let _fields = [
    {
        key: '_studentName', // keys beginning with underscore indicate keys that do not exist in underlying data
        displayName: 'Student Name',
        userDescription: 'Student first and last names pull from PowerSchool.',
        type: 'unique',
        logField: false,
        width: 150,
        frozen: true,
        headerFilter: true,
        readOnly: true,
        hozAlign: 'left',
        mutator: function (value, data, type, params, component) {
            return [data.lastName, data.firstName].join(', ');
        },
    },
    {
        key: 'studentDistrictId',
        displayName: 'Student ID',
        userDescription: 'Student identification numbers pull from PowerSchool.',
        headerFilter: true,
        type: 'unique',
        logField: false,
        readOnly: true,
    },
    {
        key: 'email',
        displayName: 'Student Email',
        userDescription: 'Student emails pull from the student survey.',
        type: 'unique',
        readOnly: false,
        formatter: (cell, victory, colors) => getFirst(cell),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'counselorName',
        displayName: 'Counselor',
        userDescription: 'Each student in the tracker is connected to one counselor. This information pulls directly from PowerSchool; therefore, it is critically important for schools to maintain accurate caseload assignments in PowerSchool.',
        type: 'category',
        logField: false,
        readOnly: true,
        headerFilter: true,
    },
    {
        key: 'currentGradeLevel',
        displayName: 'Current Grade',
        userDescription: 'Current grade level of student.',
        readOnly: true,
        logField: false,
        type: 'numeric'
    },
    {
        key: 'gender',
        displayName: 'Gender',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'category',
        readOnly: true,
        logField: false,
        headerFilter: 'select', 
        headerFilterFunc: customHeaderFunc,
        headerFilterParams: {
            values: {
                'Male': 'Male',
                'Female': 'Female',
                '' : 'All'
            }
        },
    },
    {
        key: 'raceAndEthnicity.race',
        displayName: 'Race',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'category',
        logField: false,
        readOnly: true,
        headerFilter: true
    },
    {
        key: 'englishLearner',
        displayName: 'English Learner',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'boolean',
        logField: false,
        options: {
            true: 'ELL',
            false: 'Not ELL'
        },
        readOnly: true,
        formatter: (cell, victory, colors) => cell.getValue() ? 'ELL' : 'Not ELL'
    },
    {
        key: 'specialEducation',
        displayName: 'Special Ed',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'boolean',
        logField: false,
        options: {
            true: 'Sped',
            false: 'Not Sped'
        },
        readOnly: true,
        formatter: (cell, victory, colors) => cell.getValue() ? 'Sped' : 'Not Sped'
    },
    {
        key: 'firstGeneration',
        displayName: 'First Generation',
        userDescription: 'This status pulls from the student survey.',
        type: 'boolean',
        logField: false,
        options: {
            true: 'First Generation',
            false: 'Not First Generation'
        },
        readOnly: false,
        formatter: (cell, victory, colors) => formatters.checkify(getFirst(cell), colors),
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'school.district',
        displayName: 'District',
        userDescription: 'The student’s school district.',
        readOnly: true,
        type: 'category',
        logField: false,
    },
    {
        key: 'creditsEarned',
        displayName: 'Total Credits',
        userDescription: 'This variable shows the credits the student has already earned.',
        readOnly: true,
        type: 'numeric',
        logField: false,
    },
    {
        key: 'school.name',
        displayName: 'School',
        userDescription: 'The student’s high school.',
        readOnly: true,
        type: 'category',
        logField: false,
    },
    {
        key: '_predictedCredits',
        displayName: 'Predicted EOY Credits',
        userDescription: 'This variable shows students total earned credits plus predicted credits for the current school year. The variable assumes students will earn credit for active courses where students are currently earning passing grades of 60 or higher (Naugatuck requires >70).',
        type: 'numeric',
        readOnly: true,
        mutator: function (value, data, type, params, component) {
            if (!data.creditsEarned) return null;
            if (!data.creditsPredicted) return null;

            return +(data.creditsEarned + data.creditsPredicted).toFixed(1);
        },
        logField: false,
    },
    {        
        key: 'graduationConcerns',
        displayName: 'Graduation Concerns',
        userDescription: "This field allows counselors to flag students with graduation concerns (e.g., concerns, no concerns). The tracker provides information about students’ predicted end-of-year credits, and counselors should take time to review students' credits and content/course-specific credit requirements. Please flag students with graduation concerns to then pursue targeted interventions and scheduling changes.",
        type: 'boolean',
        readOnly: false,
        options: {
            true: 'Concerns',
            false: 'No Concerns'
        },
        victory: value => !value,
        formatter: (cell, victory, colors) => {
            const value = getFirst(cell);
            if (value === null) return formatters.nullWarning(colors);
            return formatters.warnify(value == true, colors);
        },
        sorter: (a, b) => mySort(a, b),
        logField: true,
    },
    {
        key: 'GPA',
        displayName: 'GPA',
        userDescription: 'Raw GPA',
        type: 'number',
        readOnly: true,
        logField: false,
    },    
    {
        key: 'courses',
        displayName: 'Grades',
        type: 'courses',
        hideInStudentProfile: true,
        logField: false,
        userDescription: 'Student courses and grades',
        readOnly: true,
        headerSort: false,
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            if (!value) return '';
            return coursesToSVG(value);
        },
    },
    {
        key: 'passingCourseCount',
        displayName: 'Passing Courses',
        userDescription: 'Number of Passing Courses',
        type: 'number',
        readOnly: true,
        logField: false,
    },
    {
        key: '_GPABand',
        displayName: 'GPA Band',
        userDescription: "This variable shows each student’s cumulative GPA for final grades. It excludes active courses; transfer course grades are included if those grades have been properly entered into PowerSchool. Students are grouped into GPA bands in .5 increments (e.g., 0.0-0.5, 0.5-1.0). You may hover over a student’s GPA band to see their precise GPA. The GPA bands allow counselors to filter for students in GPA groupings. GPAs appear in green (vs. grey) if students have a college readiness GPA greater than 3.0.",
        type: 'category',
        readOnly: true,
        victory: value => ['3.0—3.5', '3.5—4.0', '4.0+'].filter(element => element == value).length > 0,
        mutator: function (value, data, type, params, component) {
            if (data.GPA == null) return null;
            if (data.GPA > 4) return '4.0+';

            const myGPA = Array(8)
                .fill()
                .map((x,i) => ({
                    highestBound: (i/2+0.5),
                    display: `${(i/2).toFixed(1)}—${(i/2+0.5).toFixed(1)}`
                })).find(element => element.highestBound > data.GPA)
            
            return myGPA?.display || null;
        },
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        },
        logField: false,
    },
    {
        key: '_ADA', 
        displayName: 'ADA This Year',
        userDescription: "The average daily attendance field reflects each student’s attendance rate so far during the current school year. This focus is a percent; the denominator reflects a student’s enrollment days at the school during the current year, and the numerator reflects a student’s number of days present.",
        type: 'numeric',
        logField: false,
        readOnly: true,
        victory: value => value >= 90,
        mutator: function (value, data, type, params, component) {
            const raw = data.ADA;
            return Number.isFinite(raw) ? 
                +(raw * 100).toFixed() : 
                null;
        },
        formatter: function (cell, victory, colors) {
            const value = cell.getValue();
            return formatters.badgify(value, victory(value), colors);
        }
    }
];

// check read only fields match correctly
_fields.forEach(f => {
    if (f.readOnly == undefined) {
        console.error('field is missing readonly property', f);
        return;
    }
    if (!f.key) return;
    const myField = studentSchema.properties[f.key]
    if (!myField) return;
    if (myField.readOnly == undefined) {
        console.error('field is missing readonly property in schema', myField);
        return;
    }
    if (myField.readOnly !== f.readOnly) {
        console.error('read only properties do not match between schema and fields.js', f);
    }
});

let groups = [
    {
      name: 'Student Details',
      fields: [
        'counselorName',
        'currentGradeLevel',
        'school.district',
        'englishLearner',
        'firstGeneration',
        'gender',
        'raceAndEthnicity.race',
        'school.name',
        'specialEducation',
        'email',
        'studentDistrictId',
        '_studentName',
      ]
    },
    {
      name: 'HS Academics',
      fields: [
        '_ADA',
        'GPA',
        '_GPABand',
        'courses',
        'graduationConcerns',
        'passingCourseCount',
        '_predictedCredits',
        'creditsEarned'
      ]
    }
];

export const [fields, fieldGroups] = processFieldsAndGroups(_fields, groups);
