import { defaultViews } from '@/apps/g8t/views.js';
import { matrixConfig } from '@/apps/g8t/matrix.js';
import { slides } from '@/apps/g8t/slides.js';
import { fields, fieldGroups } from '@/apps/g8t/fields.js';

export const apps = [
    {
        key: 'G8T',
        displayName: 'Risk & Opportunity',
        description: 'The Risk and Opportunity App allows educators to access Grade 8 student data, broken down into different categories. It can help educators build caseloads for incoming grade 9 classes.',
        icon: 'insights',
        applets: ['tracker', 'profile', 'protocol', 'analytics'],
        launchRoute: 'tracker',
        trackerConfig: {
            showViews: true,
            defaultView: 'Default',
            defaultViews,
            matrixConfig
        },
        analyticsConfig: {
            rowGroup: 'g8RiskAndOpportunity',
            columnGroup: 'englishLearner',
            redirectDefaultFields: [
                '_studentName',
                'gender',
                'raceAndEthnicity.race',
                'englishLearner',
                'specialEducation',
                'g8RiskAndOpportunity',
            ]
        },
        slidesConfig: {
            redirectDefaultFields: [
                '_studentName',
                'middleSchool',
                'gender',
                'raceAndEthnicity.race',
                'englishLearner',
                'specialEducation',
                'g8RiskAndOpportunity',
                '_ADA',
                'g8GPA'
            ]
        },
        profileConfig: {
            showCourses: false
        },
        fields, 
        fieldGroups,
        slides,
        chatAvailable: false,
        availableGradeLevels: [8],
        databases: [ 'main' ],
        defaultGradeLevel: 8,
        multiSchool: false,
        trackUsage: true
    }
];