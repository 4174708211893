/* eslint no-unused-vars: "off" */
import { formatters } from '@/functions/formatters.js';
import studentSchema from '@/schemas/studentSchema.json';
import { processFieldsAndGroups, customHeaderFunc, processTrueFalse } from '@/functions/utils';

const createTrafficLightTest = tests => [
    {
        test: tests[0],
        colorName: 'success',
        BGcolorName: 'bg'
    },
    {
        test: tests[1],
        colorName: 'info',
        BGcolorName: 'contrastFull'
    },
    {
        test: tests[2],
        colorName: 'warning',
        BGcolorName: 'bg'
    }
];

const ROtests = value => [
    {
        test: value == 'High Opportunity',
        colorName: 'success',
        BGcolorName: 'bg'
    },
    {
        test: value == 'Opportunity',
        colorName: 'gradeB',
        BGcolorName: 'contrastFull'
    },
    {
        test: value == 'Vulnerable',
        colorName: 'info',
        BGcolorName: 'contrastFull'
    },
    {
        test: value == 'High Risk',
        colorName: 'warning',
        BGcolorName: 'bg'
    }
];
const ADAtests = value => createTrafficLightTest(
    [
        value > 90,
        value <= 90 && value > 80,
        value <= 80
    ]
);
const GPAtests = value => createTrafficLightTest(
    [
        value > 3,
        value <= 3 && value > 2,
        value <= 2
    ]
);
const suspensionTests = value => createTrafficLightTest(
    [
        value == 0,
        false,
        value > 0
    ]
);

function roSorter (a, b, aRow, bRow, column, dir, sorterParams) {
    const list = {'High Opportunity': 4, 'Opportunity': 3, 'Vulnerable': 2, 'High Risk': 1};
    if (list[a] === undefined) return -1;
    if (list[b] === undefined) return 1;
    const result = 'asc' ?
        list[a] - list[b]:
        list[b] - list[a];
    return result;
}

const roOptions = {
    'High Risk': 'High Risk',
    'Vulnerable': 'Vulnerable',
    'Opportunity': 'Opportunity',
    'High Opportunity': 'High Opportunity'
};

const roFilterOptions = {
    values: {
        'High Risk': 'High Risk',
        'Vulnerable': 'Vulnerable',
        'Opportunity': 'Opportunity',
        'High Opportunity': 'High Opportunity',
        '': 'All'
    }
};

const roOrder = ['High Risk', 'Vulnerable', 'Opportunity', 'High Opportunity'];

let _fields = [
    {
        key: '_studentName', // keys beginning with underscore indicate keys that do not exist in underlying data
        displayName: 'Student Name',
        userDescription: 'Student first and last names pull from PowerSchool.',
        type: 'unique',
        width: 150,
        frozen: true,
        headerFilter: true,
        readOnly: true,
        hozAlign: 'left',
        mutator: function (value, data, type, params, component) {
            return [data.lastName, data.firstName].join(', ');
        },
    },
    {
        key: 'g8RiskAndOpportunity',
        displayName: 'Grade 8 R&O Status',
        width: 200,
        userDescription: 'Student Grade 8 risk & opportunity status',
        type: 'category',
        readOnly: true,
        options: roOptions,
        order: roOrder,
        sorter: roSorter,
        headerFilter: 'select', 
        headerFilterFunc: customHeaderFunc,
        headerFilterParams: roFilterOptions,        
        formatter: (cell, victory, colors) => {
            const value = cell.getValue();
            return formatters.trafficLight(value, ROtests(value), colors);
        },
    },
    {
        key: 'g8ADA',
        displayName: 'ADA',
        userDescription: 'Student grade 8 end of year average daily attendance',
        type: 'numeric',
        hideInFilters: true,
        readOnly: true,
    },
    {
        key: '_ADA', 
        displayName: 'ADA This Year',
        userDescription: `Student grade 8 end of year average daily attendance`,
        type: 'numeric',
        logField: false,
        readOnly: true,
        mutator: function (value, data, type, params, component) {
            const raw = data.g8ADA;
            return Number.isFinite(raw) ? 
                +(raw * 100).toFixed() : 
                null;
        },
        formatter: (cell, victory, colors) => {
            const value = cell.getValue();
            return formatters.trafficLight(value, ADAtests(value), colors);
        },
        bands: [
            {test: value => value < 90, colorCode: 'warning', label: '< 90% ADA'},
            {test: value => value < 95 && value >= 90, colorCode: 'info', label: '90-95% ADA'},
            {test: value => value >= 95, colorCode: 'success', label: '≥ 95% ADA'}
        ]
    },
    {
        key: 'g8GPA',
        displayName: 'GPA',
        userDescription: 'Student grade 8 end of year grade point average',
        type: 'numeric',
        readOnly: true,
        formatter: (cell, victory, colors) => {
            const value = cell.getValue();
            return formatters.trafficLight(value, GPAtests(value), colors);
        },
        bands: [
            {test: value => value < 2, colorCode: 'warning', label:'< 2.0 GPA'},
            {test: value => value >= 2 && value < 3, colorCode: 'info', label:'2.0-2.99 GPA'},
            {test: value => value >= 3, colorCode: 'success', label:'≥ 3.0 GPA'}
        ]
    },
    {
        key: 'g8Suspensions',
        displayName: 'Grade 8 Suspensions',
        userDescription: 'Student grade 8 end of year number of suspensions',
        type: 'numeric',
        readOnly: true,
        formatter: (cell, victory, colors) => {
            const value = cell.getValue();
            return formatters.trafficLight(value, suspensionTests(value), colors);
        },
        bands: [
            {test: value => value > 1, colorCode: 'warning', label:'2+ suspensions'},
            {test: value => value == 1, colorCode: 'info', label:'1 suspension'},
            {test: value => value == 0, colorCode: 'success', label:'0 suspensions'}
        ]
    },
    {
        key: 'g8RiskFactors',
        displayName: 'Grade 8 Risk Factors',
        userDescription: 'Number of risk factors (ADA < 90, 1+ suspension, GPA < 3.0.)',
        type: 'numeric',
        readOnly: true,
        formatter: (cell, victory, colors) => {
            const value = cell.getValue();
            return formatters.trafficLight(value, suspensionTests(value), colors);
        },
        bands: [
            {value: 0, colorCode: 'success', label:'0 risk factors'},
            {value: 1, colorCode: 'gradeB', label:'1 risk factor'},
            {value: 2, colorCode: 'info', label:'2 risk factors'},
            {value: 3, colorCode: 'warning', label:'3 risk factors'},
        ]
    },
    {
        key: 'studentDistrictId',
        displayName: 'Student ID',
        userDescription: 'Student identification numbers pull from PowerSchool.',
        headerFilter: true,
        type: 'unique',
        readOnly: true,
    },
    {
        key: 'currentGradeLevel',
        displayName: 'Current Grade',
        userDescription: 'Current grade level of student.',
        readOnly: true,
        type: 'numeric'
    },
    {
        key: 'gender',
        displayName: 'Gender',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'category',
        readOnly: true,
        headerFilter: 'select', 
        headerFilterFunc: customHeaderFunc,
        headerFilterParams: {
            values: {
                'Male': 'Male',
                'Female': 'Female',
                '' : 'All'
            },
        }
    },
    {
        key: 'raceAndEthnicity.race',
        displayName: 'Race / Ethnicity',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'category',
        readOnly: true,
        headerFilter: true
    },
    {
        key: 'englishLearner',
        width: 90,
        displayName: 'English Learner',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'boolean',
        options: {
            true: 'ELL',
            false: 'Not ELL'
        },
        headerFilter: 'select', 
        headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
            if (!headerValue) return true;
            return processTrueFalse(headerValue) == rowValue; //must return a boolean, true if it passes the filter.
        },
        headerFilterParams: {
            values: {
                true: 'ELL', 
                false: 'Not ELL',
                '': 'All'
            }
        },
        readOnly: true,
        formatter: (cell, victory, colors) => cell.getValue() ? 'ELL' : 'Not ELL'
    },
    {
        key: 'specialEducation',
        width: 90,
        displayName: 'Special Ed',
        userDescription: 'Student demographics pull from demographic information in PowerSchool.',
        type: 'boolean',
        options: {
            true: 'Sped',
            false: 'Not Sped'
        },
        headerFilter: 'select', 
        headerFilterFunc: (headerValue, rowValue, rowData, filterParams) => {
            if (!headerValue) return true;
            return processTrueFalse(headerValue) == rowValue; //must return a boolean, true if it passes the filter.
        },
        headerFilterParams: {
            values: {
                true: 'Sped', 
                false: 'Not Sped',
                '': 'All'
            }
        },        
        readOnly: true,
        formatter: (cell, victory, colors) => cell.getValue() ? 'Sped' : 'Not Sped'
    },
    {
        key: 'school.district',
        displayName: 'District',
        userDescription: 'The student’s school district.',
        headerFilter: true,
        readOnly: true,
        type: 'category',
    },
    {
        key: 'school.name',
        displayName: 'School',
        userDescription: 'The student’s high school.',
        headerFilter: true,
        readOnly: true,
        type: 'category',
    },
    {
        key: 'middleSchool',
        displayName: 'Middle School',
        userDescription: 'Student middle school',
        headerFilter: true,
        type: 'category',
        readOnly: true
    }
];

// check read only fields match correctly
_fields.forEach(f => {
    if (f.readOnly == undefined) {
        console.error('field is missing readonly property', f);
        return;
    }
    if (!f.key) return;
    const myField = studentSchema.properties[f.key]
    if (!myField) return;
    if (myField.readOnly == undefined) {
        console.error('field is missing readonly property in schema', myField);
        return;
    }
    if (myField.readOnly !== f.readOnly) {
        console.error('read only properties do not match between schema and fields.js', f);
    }
});

let groups = [  
    {
      name: 'Student Details',
      fields: [
        'currentGradeLevel',
        'school.district',
        'englishLearner',
        'gender',
        'middleSchool',
        'raceAndEthnicity.race',
        'school.name',
        'specialEducation',
        'studentDistrictId',
        '_studentName',
      ]
    },
    {
        name: 'Grade 8 Perfomance',
        fields: [
          '_ADA',
          'g8GPA',
          'g8RiskAndOpportunity',
          'g8Suspensions',
          'g8RiskFactors'
        ]
    },
];

export const [fields, fieldGroups] = processFieldsAndGroups(_fields, groups);