import { fields } from "./fields"

export const slides = [
    {
        title: 'Students by Number of Risk Factors',
        charts: [
            {
                chartConfig: {
                    type: 'pie',
                    title: '',
                    primaryField: fields.find(field => field.key == 'g8RiskFactors'),
                    formatLabel: str => `${str} risk factors`
                },                        
            }            
        ],
        questions: [
            'What stands out in the data?',
            'What do the data tell us about our incoming class?',
            'How can we pursue proactive supports for our incoming freshmen?',
            'What strategies can we pursue for all students, and those with one or more risk factors?'
        ]
    },
    {
        title: 'Student Attendance, GPA, and Behavior Performance Bands',
        charts: [
            {
                chartConfig: {
                    type: 'singleBand',
                    title: 'Attendance',
                    primaryField: fields.find(field => field.key == '_ADA'),
                },
            },
            {                        
                chartConfig: {
                    type: 'singleBand',
                    title: 'GPA',
                    primaryField: fields.find(field => field.key == 'g8GPA'),
                },                        
            },
            {                        
                chartConfig: {
                    type: 'singleBand',
                    title: 'Suspensions',
                    primaryField: fields.find(field => field.key == 'g8Suspensions'),
                },                        
            }            
        ],
        questions: [
            'What stands out in the data?  What do you notice about students attendance, grades, and behavior in middle school?',
            'What might the data tell us about students’ experiences in middle school?',
            'How can we support students in continuing on a good trajectory or facilitating a clean start in high school?'
        ]
    },
    {
        title: 'Risk and Opportunity Breakdown by ELL Status',
        charts: [
            {
                chartConfig: {
                    type: 'grouped',
                    title: '',
                    primaryField: fields.find(field => field.key == 'englishLearner'),
                    secondaryField: fields.find(field => field.key == 'g8RiskAndOpportunity'),
                    stacked: true,
                    showAsPercentage: true
                }                        
            },
        ],
        questions: [
            'What opportunity gaps stand out in the data?',
            'If we don’t address these gaps, what could that mean for our students?',
            'How can we work to disrupt these gaps?',
            'How do we avoid labelling students as the transition into high school, and instead provide them with the equitable supports they need?'
        ]
    },    {
        title: 'Risk and Opportunity Breakdown by Special Education Status',
        charts: [
            {
                chartConfig: {
                    type: 'grouped',
                    title: '',
                    primaryField: fields.find(field => field.key == 'specialEducation'),
                    secondaryField: fields.find(field => field.key == 'g8RiskAndOpportunity'),
                    stacked: true,
                    showAsPercentage: true
                }                        
            },
        ],
        questions: [
            'What opportunity gaps stand out in the data?',
            'If we don’t address these gaps, what could that mean for our students?',
            'How can we work to disrupt these gaps?',
            'How do we avoid labelling students as the transition into high school, and instead provide them with the equitable supports they need?'
        ]
    },    {
        title: 'Risk and Opportunity Breakdown by Race / Ethnicity',
        charts: [
            {
                chartConfig: {
                    type: 'grouped',
                    title: '',
                    primaryField: fields.find(field => field.key == 'raceAndEthnicity.race'),
                    secondaryField: fields.find(field => field.key == 'g8RiskAndOpportunity'),
                    stacked: true,
                    showAsPercentage: true
                }                        
            },
        ],
        questions: [
            'What opportunity gaps stand out in the data?',
            'If we don’t address these gaps, what could that mean for our students?',
            'How can we work to disrupt these gaps?',
            'How do we avoid labelling students as the transition into high school, and instead provide them with the equitable supports they need?'
        ]
    },
]