import Ajv from 'ajv';
const ajv = new Ajv() // options can be passed, e.g. {allErrors: true}

export const validate = (schema, data) => {    
    const valid8 = ajv.compile(schema);
    
    const isValid = valid8(data);
    if (!isValid) {
        console.error('Submitted data does not match schema.');
        console.info(valid8.errors);
    }
    
    return isValid;
};

