export const FBProjects = [
    {
        key: 'pst-prod-9000',
        config: {
            apiKey: 'AIzaSyCgndBexlgw8TI7hv-RRy08AybxntAOdLA',
            authDomain: 'pst-prod-9000.firebaseapp.com',
            projectId: 'pst-prod-9000',
            storageBucket: 'pst-prod-9000.appspot.com',
            messagingSenderId: '660840462987',
            appId: '1:660840462987:web:acbdc24a093b62bd21328e',
            measurementId: 'G-J6TRBFX90L'        
        }
    },
    {
        key: 'dev-dev-pst-final-2',
        config: {
            apiKey: 'AIzaSyAa70Lz_AK1H0N4YH3VGXJfL_6WbB4fGgQ',
            authDomain: 'dev-dev-pst-final-2.firebaseapp.com',
            projectId: 'dev-dev-pst-final-2',
            storageBucket: 'dev-dev-pst-final-2.appspot.com',
            messagingSenderId: '33759704905',
            appId: '1:33759704905:web:9f439f3e1f8207be49cc98',
            measurementId: 'G-WDJ4EPQYVR'       
        }
    }
];