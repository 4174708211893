import { FBProjects } from '@/firebase/projects';

const manualSelectEnvironment = null; // To manually select the environment, change null on this line to a key listed below

export const environments = [
    {
        key: 'production',
        databases: {
            main: 'prod_students',
            notes: 'prod_notes',
            users: 'users',
            tags: 'prod_tags'
        },
        hosts: ['pst-prod-9000.web.app', 'pst-prod-9000.firebaseapp.com', 'pst.ctrise.org', 'apps.ctrise.org'],
        schoolCollection: 'prod_all_schools',
        project: 'pst-prod-9000'
    },
    {
        key: 'beta',
        databases: {
            main: 'prod_students',
            notes: 'prod_notes',
            users: 'users',
            tags: 'prod_tags'
        },
        hosts: ['risebeta.web.app', 'risebeta.firebaseapp.com', 'beta.ctrise.org'],
        schoolCollection: 'prod_all_schools',
        project: 'pst-prod-9000'
    },
    {
        key: 'demo',
        databases: {
            main: 'demo_students',
            notes: 'demo_notes',
            users: 'users',
            tags: 'demo_tags'
        },
        hosts: ['pst-demo-9000.web.app', 'pst-demo-9000.firebaseapp.com', 'demopst.ctrise.org', 'demoapps.ctrise.org'],
        schoolCollection: 'demo_all_schools',
        project: 'pst-prod-9000'
    },
    {
        key: 'development',
        databases: {
            main: 'web_dummy_students',
            notes: 'web_dummy_notes',
            users: 'users',
            tags: 'web_dummy_tags',
            // events: 'web_dummy_events' // Events for usage tracking.
        },
        hosts: ['localhost', 'dev-dev-pst-final-2.web.app', 'dev-dev-pst-final-2.firebaseapp.com', 'alpha.ctrise.org'],
        schoolCollection: 'web_dummy_schools',
        project: 'dev-dev-pst-final-2'
    }
];

export const currentEnvironment = manualSelectEnvironment ?
    environments.find(e => e.key == manualSelectEnvironment):
    environments.find(e => e.hosts.includes(window.location.hostname));

if (!currentEnvironment) console.error('The current host is not recognized. Edit config.js to include the current path.');

export const firebaseConfig = FBProjects.find(project => project.key == currentEnvironment.project).config;