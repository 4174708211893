import variables from '@/assets/styles/colors.scss';

const markingPeriods = ['Q1', 'Q2', 'S1', 'Q3', 'Q4', 'S2', 'Y1'];

const getRectangle = ({i, j, width, height, color}) => `
    <rect x="${width * j}" y="${height * i}" height="${height - 1}" width="${width - 1}" fill="${color}"></rect>
`;

const getAllRectangles = ({colorTextArray, height, width}) => colorTextArray.map(
    (row, i) => row.map(
        (color, j) => getRectangle({i, j, width, height, color})
    )
);

const colorMatrixToSVG = ({colorTextArray, height, width}) => `
    <svg width="80" height="42" xmlns="http://www.w3.org/2000/svg">
        ${getAllRectangles({colorTextArray, height, width})}
    </svg>
`;

const colorMap = ['A', 'B', 'C', 'D', 'F', 'P']
    .map(e => ({letter: e, color: variables['defaultgrade' + e]}));

const getColor = letterGrade => colorMap.find(
        element => new RegExp(`^${element.letter}`).test(letterGrade)
    )?.color || 'grey';

export const coursesToSVG = courses => colorMatrixToSVG({
    height: 6, 
    width: 10,
    colorTextArray: Object.keys(courses).map(
        key => markingPeriods.map(
            markingPeriod => getColor(courses[key].letterGrade[markingPeriod])
        )
    )
});
