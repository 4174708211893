import { getAuth, signOut } from 'firebase/auth';
import { hasPower as hasPowerFn} from '@/core/user.js';
import { installedApps } from '@/apps/apps.js';
import { roles as allRoles, powers } from '@/apps/roles';

export const installSession = (app, options) => {

    // Initialize $user
    app.config.globalProperties.$user = null;

    app.config.globalProperties.session = {
        async processLogIn(result) {

            // load in school meta data
            const mySchools = await app.config.globalProperties.fb.schools.getMetaData();
            app.config.globalProperties.store.state.schools = mySchools.schools;

            // start the idle countdown timer
            app.config.globalProperties.idle.start();

            // set current user
            const userDoc = await app.config.globalProperties.fb.user.getMyself(result.user.uid);
            app.config.globalProperties.session.setCurrentUser({userDoc, uid: result.user.uid});

            // set user preferences
            app.config.globalProperties.store.setUserPreferences();

            // set the current school to the default
            app.config.globalProperties.store.setSchool(app.config.globalProperties.$user.defaultSchool);

            return true;
        },
        setDefaultGradeLevel() {
            // set the current grade to default

            const gradeLevelToSet = app.config.globalProperties.$user.gradeLevel.length == 1 ?
                app.config.globalProperties.$user.gradeLevel[0] :
                app.config.globalProperties.config.defaultGradeLevel;

            app.config.globalProperties.store.setGradeLevel(gradeLevelToSet);

        },
        launchApp(appKey) {

            this.clearAndUnsubscribe();

            app.config.globalProperties.store.setLaunched(true);

            app.config.globalProperties.store.setApp(appKey);

            const myApp = installedApps.find(app => app.key == appKey);

            if (!myApp) {
                console.error('No app found');
                return;
            }

            const defaultConfig = {
                roles: allRoles,
                powers
            };
            
            app.config.globalProperties.config = {
                ...defaultConfig,
                ...app.config.globalProperties.config, 
                ...myApp
            };

            this.setDefaultGradeLevel();

            if (!app.config.globalProperties.store.state.chosenSchool) {
                // set the current school to the default
                app.config.globalProperties.store.setSchool(app.config.globalProperties.$user.defaultSchool);
            }

            app.config.globalProperties.ee.emit('launchApp');

            // subscribe to Firebase updates
            app.config.globalProperties.fb.subscribe();

            return myApp.launchRoute;
        },
        processLogOut() {

            this.clearAndUnsubscribe();

            const auth = getAuth();
            signOut(auth).then(() => { // Sign-out successful.

                // reset UI
                app.config.globalProperties.store.resetUI();

                // // clear Users
                // app.config.globalProperties.store.setUsers([]);

                // stop the idle countdown timer
                app.config.globalProperties.idle.stop(); 

                // cause the app to navigate to the login page
                app.config.globalProperties.ee.emit('userLoggedOut');

            }).catch(error => {
                console.info(error);
            });
        },
        clearAndUnsubscribe() {
            // cause the app to clear any local state
            app.config.globalProperties.ee.emit('clearLocalState');
            
            // clear the store
            app.config.globalProperties.store.clear();

            // close chat
            app.config.globalProperties.store.setChatVisible(false);

            // unsubscribe from Firebase updates
            app.config.globalProperties.fb.unsubscribeAll();
        },
        resubscribe({grade, schoolKey}) {
            this.clearAndUnsubscribe();

            // set grade
            app.config.globalProperties.store.setGradeLevel(grade);

            // set school
            app.config.globalProperties.store.setSchool(schoolKey);

            // subscribe to Firebase updates
            app.config.globalProperties.fb.subscribe();

        },
        setCurrentUser({userDoc, uid}) {

            const getGradeLevel = gradeLevel => {
                if (!Array.isArray(gradeLevel) || !gradeLevel?.length) {
                    console.error('Grade level field not correctly set up for this user');
                    return;
                }
                return gradeLevel.map(element => Number(element));
            };

            const gradeLevel = getGradeLevel(userDoc.gradeLevel); // [8, 9, 10, 11, 12];

            // set schools a user can access
            const schools = (userDoc.district == 'RISENetwork' || userDoc.roles.includes('superuser')) ?
                app.config.globalProperties.store.state.schools.map(school => school.key):
                userDoc.schools;

            const district = userDoc.district;

            const defaultPreferences = {
                rowWidth: 'Default',
                colorTheme: 'default',
                showHints: true,
                defaultSchool: schools[0],
            };

            const preferences = {
                ...defaultPreferences, ...userDoc.preferences
            };

            // merge views (nested objects are note assigned above)
            const customViews = { PST: '', G8T: '', GLT: '' };
            const defaultView = { PST: 'Academic Performance', G8T: 'Default', GLT: 'Default' }; // should be imported from app config files

            preferences.customViews = { ...customViews, ...userDoc.preferences.customViews };
            preferences.defaultView = { ...defaultView, ...userDoc.preferences.defaultView };

            const defaultSchool = preferences.defaultSchool;
            const displayName = [userDoc.firstName, userDoc.lastName].join(' ');
            const initials = displayName.split(' ').map(e => e[0]).join('').slice(0, 2);
            const roles = userDoc.roles || [];
            const availableApps = [...new Set(
                allRoles.filter(role => roles.includes(role.key))
                    .map(role => role.app)
            )];
            const hasPower = power => hasPowerFn({power, roles, app: app.config.globalProperties.store.state.currentApp});

            // global user object
            app.config.globalProperties.$user = {
                displayName, uid, roles, gradeLevel, 
                preferences, schools, district, defaultSchool, 
                availableApps, initials, hasPower
            };
        }
    };
};
