
// IdleJs Library
const IdleJs = require('@/core/idle.js');

// Events library
const EventEmitter = require('events');

export const installPlugins = (app, options) => {

    app.config.globalProperties.ee = new EventEmitter();

    app.config.globalProperties.showTooltip = str => app.config.globalProperties.ee.emit('showTooltip', str);

    app.config.globalProperties.idle = new IdleJs({
        idle: 300000, //5 minutes
        onIdle: () => app.config.globalProperties.ee.emit('userIdle'),
        onActive: () => app.config.globalProperties.ee.emit('userNotIdle'),
        keepTracking: true,
        startAtIdle: false,
    });
};
