<template>
    <div class="chat-container">
        <Modal data-cy="edit-message-modal" ref="editMsg" title="Edit Message" instructions="Edit Message" @success="updateMessage">
            <textarea data-cy="edit-message-textarea" cols="30" rows="3" v-model="editMsgText" placeholder="Type here"></textarea>
        </Modal>
        <div data-cy="chat-title" class="chat-title">
            <span v-if="searchActive">
                <input data-cy="chat-search-input" v-model="searchStr" placeholder="Type here" @keydown.enter="submitSearch">
            </span>
            <span v-else>
                {{ chatTitle }}
            </span>
            <div v-if="filterActive" data-cy="chat-clear-search" class="chat-title-button" @click="clearFilters">✖</div>
            <div v-else data-cy="chat-search-icon" class="chat-title-button" @click="clickOnSearchIcon">
                <span class="material-icons" style="font-size: 18px; position:relative; top:1px; left: 2px;">search</span>
            </div>
        </div>
        <div data-cy="message-area" class="message-area" id="message-area">
            <div class="message-container" v-for="(item, index) in messagesToDisplay" :key="index">
                <div v-if="item.type=='date'" class="date-badge">
                    {{ item.dateString }}
                </div>
                <div v-else>
                    <div class="message-author">{{ item.author }}</div>
                    <div v-if="isMyOwnNote(item)" class="message-tools">
                        <span data-cy="edit-message" class="material-icons edit-message-button" @click="launchEdit(item)">
                        edit
                        </span>
                        <span data-cy="delete-message" class="delete-message-button" @click="deleteMessage(item)">
                        ✖
                        </span>
                    </div>
                    <Message :content="item.content" :studentID="item.studentID" :studentFocus="student" @clickOn="setSearch"/>
                </div>
            </div>
        </div>
        <div class="input-area">
            <div :class="tagListClasses" class="tagList" ref=tagList>
                <div 
                    class="tag" 
                    :class="{ activeTagClass: index === activeTag}"
                    v-for="(tag, index) in matchingTags" :key="index" 
                >
                    <div @mouseover="activeTag = index" @click="addTag">
                        {{ tag }}
                    </div>
                </div>
            </div>
            <div v-if="$user.hasPower('contributeToChat')">
                <textarea 
                    data-cy="chat-message-textarea"
                    ref="input-textarea" cols="30" rows="3" v-model="myMessage"
                    @keydown.enter="addTag"
                    @keydown.down="nextActiveTag"
                    @keydown.up="previousActiveTag"
                ></textarea>
                <CustomButton data-cy="add-message" @click="addMessage" buttonText="Send" />
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/customButton.vue';
import Modal from '@/components/modal.vue';
import Message from '@/components/message.vue';
import { matchingTags, addTag } from '@/functions/chattags.js';

export default {
    name: 'Chat',
    props: {
        student: {
            type: Object,
            default: {}
        },
    },
    data () {
        return {
            messages: this.store.state.notes,
            myMessage: '',
            editMsgText: '',
            messageBeingEdited: null,
            currentTag: '',
            matchingTags: [],
            activeTag: 0,
            activeTagClass: 'activeTagClass',
            searchStr: '',
            searchActive: false,
        }
    },
    computed: {
        tags: function () {
            return this.getTags(this.store.state.notes);
        },
        tagListClasses: function () {
            return this.matchingTags.length ? '' : 'hidden';
        },
        messagesToDisplay: function () {
            const myMessageList = this.filterMessagesToDisplay(this.searchStr, this.store.state.notes, this.student.id)
                .map(element => this.addGradeLevelToMessage(element)) // won't need this line after migrating existing notes
                .filter(element => element.gradeLevel == this.store.state.showingGradeLevel);
            return this.addDatesToMessageList(myMessageList);
        },
        chatTitle: function () {
            if (this.searchStr) return 'Search: ' + this.searchStr;
            if (this.student.name) return 'Student: ' + this.student.name;
            return 'All Messages';
        },
        filterActive: function () {
            if (this.student.name || this.searchStr) return true;
        }
    },
    watch: {
        myMessage: function() {
            this.matchingTags = matchingTags(this.myMessage, this.tags);
            this.activeTag = 0;
        },
        student: function() {
            if (this.student.name) {
                this.searchStr = '';
                this.scrollToBottom();
            }
        }
    },
    methods: {
        addGradeLevelToMessage(message) {
            if (message.gradeLevel !== undefined) return message;
            message.gradeLevel = 12;
            return message;
        },
        filterMessagesToDisplay(searchStr, notes, selectedStudentID) { 
            return searchStr ?
                notes.filter(element => element.content.toLowerCase().includes(searchStr.toLowerCase())) :
                selectedStudentID ? 
                    notes.filter(element => element.studentID == selectedStudentID) :
                    notes;
        },
        getTags(notes) {
            let arr = [];
            notes.map(element => element.content)
                .forEach(str => {
                    arr = [].concat(arr, str.match(/#\w+/gi));
                });
            arr = arr.filter(element => element !== null).map(str => str.substr(1)); // chop off the #
            const result = [...new Set(arr)];
            return result;
        },
        addDatesToMessageList(messageList) {
            if (!messageList.length) return messageList;

            let list = messageList.sort((a, b) => (a.datetime > b.datetime) ? 1 : -1);
            let result = [];
            let lastDate = new Date(list[0].datetime);

            // add date for oldest message
            result.push({
                type: 'date',
                dateString: this.getDisplayDate(lastDate)
            });

            list.forEach(message => {                
                const newDate = new Date(message.datetime);

                // if message was written on different date to previous message
                if (newDate.toDateString() !== lastDate.toDateString()) {
                    result.push({
                        type: 'date',
                        dateString: this.getDisplayDate(newDate)
                    });
                    lastDate = newDate;
                }

                message.type = 'message';
                result.push(message);
            });

            return result;
        },
        getDisplayDate(date, today=new Date()) {

            const dateDiffInDays = (a, b) => {
                const msPerDay = 1000 * 60 * 60 * 24;
                // Discard the time and time-zone information.
                const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
                const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

                return Math.floor((utc2 - utc1) / msPerDay);
            };

            const delta = dateDiffInDays(date, today);

            const specialText = [
                {
                    delta: 0,
                    text: 'Today'
                },
                {
                    delta: 1,
                    text: 'Yesterday'
                }
            ].find(element => element.delta == delta);

            return specialText ? specialText.text : date.toDateString();
        },
        isMyOwnNote(note) {
            if (!note.uid) return false;
            if (!this.$user.uid) return false;
            return note.uid === this.$user.uid;
        },
        addMessage() {
            if (!this.myMessage) return;

            const messageToAdd = {
                author: this.$user.displayName,
                uid: this.$user.uid,
                content: this.myMessage,
                studentID: this.student.id || '',
                gradeLevel: this.store.state.showingGradeLevel,
                datetime: Date.parse(new Date())
            };

            // add to firestore
            this.fb.messages.add(messageToAdd);
            // this.fb.events.add({ action: 'create', target: 'chatMessage' });
            this.myMessage = '';
            this.scrollToBottom();
        },
        scrollToBottom() {
            const myDiv = document.getElementById('message-area');
            setTimeout(() => {
                if (myDiv) {
                    myDiv.scrollTop = myDiv.scrollHeight;
                }
            }, 50);
        },
        deleteMessage(msg) {
            this.fb.messages.delete(msg);
        },
        launchEdit(msg) {
            this.messageBeingEdited = msg;
            this.editMsgText = msg.content;
            this.$refs.editMsg.show();
        },
        updateMessage() {
            this.messageBeingEdited.content = this.editMsgText;
            this.editMsgText = '';
            this.fb.messages.update(this.messageBeingEdited.docID, this.messageBeingEdited);
        },
        addTag(e=null) {
            // add hashtag to message being typed
            if (this.matchingTags.length) {
                this.myMessage = addTag(this.myMessage, this.matchingTags[this.activeTag]);
                this.$refs['input-textarea'].focus();
                if (e) e.preventDefault();
            }
        },
        nextActiveTag(e) {
            if (this.matchingTags.length) {
                this.activeTag++;
                if (this.activeTag >= this.matchingTags.length - 1) this.activeTag = 0;
                e.preventDefault();
            }
        },
        previousActiveTag(e) {
            if (this.matchingTags.length) {
                this.activeTag--;
                if (this.activeTag < 0 ) this.activeTag = this.matchingTags.length - 1;
                e.preventDefault();
            }
        },
        clearFilters() {
            this.$emit('clearFilters'); // sets this.student to {}
            this.searchStr = '';
            this.scrollToBottom();
        },
        clickOnSearchIcon() {
            this.searchActive = !this.searchActive;
        },
        submitSearch() {
            this.searchActive = false;
        },
        setSearch(str) {
            this.$emit('clearFilters'); // sets this.student to {}
            this.searchStr = str;
            this.searchActive = false;
            this.scrollToBottom();
        },
    },
    mounted() {
        this.scrollToBottom();
        this.ee.on('chatToggled', visible => {
            if (!visible) return;
            this.scrollToBottom();
        });
        this.ee.on('gradeLevelChange', newLevel => {
            this.clearFilters();
        });
    },
    components: {
        CustomButton, Modal, Message,
    }
}
</script>

<style lang="scss">
@import '../assets/styles/colors';

.chat-container {
    float: right;
    width: 300px;
    height: calc(100vh - 50px);
    background-color: var(--color-contrast-lower);
}

.chat-title {
    padding: 10px;
    font-weight: bold;
    background-color: var(--color-success);
    color: var(--color-bg);
    position: fixed;
    width:300px;
    z-index: 0.9;
    input {
        width: 240px;
    }
}

.chat-title-button {
    float: right;
    &:hover {
        cursor: pointer;
        color: var(--color-contrast-medium);
    }
}

.message-area {
    position: relative;
    top: 40px;
    height: calc( 100vh - 250px);
    overflow: auto;
    font-family: 'Lato', sans-serif;
    margin: 10px;
    .message-container {
        padding: 5px;
    }

    .message-author {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .message-tools {
        float: right;
        position: relative;
        top: -25px;
    }
}

.edit-message-button {
    font-size: 1.5rem;
    position: relative;
    top: 1px;
    color: var(--color-contrast-high);
    &:hover {
        color: var(--color-primary);
        cursor: pointer;
    }
}

.input-area {
    position: relative;
    top: 45px;
    left: 15px;
    textarea {
        width: 250px;
        resize: none;
        display: block;
    }
}


.delete-message-button {
    color: var(--color-contrast-high);
    &:hover {
        color: var(--color-warning);
        cursor: pointer;
    }
}

.tagList {
    background-color: var(--color-bg);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 5px 5px 10px;
    cursor: pointer;
}

.tag {
    padding: 2px;
}

.hidden {
    display: none;
}

.activeTagClass {
    background-color: var(--color-primary);
    color: var(--color-bg);
}

.date-badge {
    background-color: var(--color-contrast-high);
    text-align: center;
    padding: 3px;
    border-radius: 15px;
    color: var(--color-bg);
    margin: auto;
    width: 180px;
}

</style>