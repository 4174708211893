import { fields } from '@/apps/glt/fields.js';

export const defaultViews = [
    {
        name: 'Default',
        fields: [
            '_studentName',
            'gender',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
            'courses',
            '_GPABand',
            'creditsEarned',
            'passingCourseCount',
        ]
    },
].map(element => ({
    name: element.name,
    filters: element.filters,
    fields: element.fields.map(field => fields.find(e => e.key == field).displayName)
}));
