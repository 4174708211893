import { apps as PSTApps } from '@/apps/pst/config';
import { apps as GLTApps } from '@/apps/glt/config';
import { apps as G8TApps } from '@/apps/g8t/config';
import { apps as usersApps } from '@/apps/users/config';

const allApps = [PSTApps, GLTApps, G8TApps, usersApps].flat();

export const installedApps = [
    'PST', 
    'pstAdmin', 
    // 'GLT', 
    // 'G8T', 
    // 'users' // This app is a prototype only. To turn this on, events areas need to be uncommented in firebase.js
].map(key => allApps.find(app => app.key == key)).filter(app => app !== undefined);
