<template>
    <div class="select-container">
        <div class="select-title">{{ title }}</div>
        <div class="select" v-if="optionGroups">
            <select v-model="modelValue" @change="processChange($event)">
                <optgroup v-for="(optionGroup, i) in optionGroups" :key="i" :label="optionGroup">
                    <option v-for="(item, j) in options.filter(e => e.optionGroup == optionGroup)" :key="j" >
                        {{ item[displayField] }}
                    </option>
                </optgroup>
            </select>
        </div>
        <div class="select" v-else>
            <select v-model="modelValue" @change="processChange($event)">
                <option v-for="(item, index) in options" :key="index" >{{ item[displayField] }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: false,
            type: String
        },
        groupOrder: {
            required: false,
            type: Array
        },
        options: Array,
        modelValue: String,
        displayField: String
    },
    computed: {
        optionGroups: function() {
            if (!this.options.length) return false;
            if (this.options.every(item => !item.optionGroup)) return false;
            this.options.forEach(item => {
                if (!item.optionGroup) {
                    item.optionGroup = '(uncategorized)';
                }
            })
            return [...new Set(this.options.map(option => option.optionGroup))]
            .sort((a, b) => {
                if (this.groupOrder) {
                    return this.groupOrder.indexOf(a) > this.groupOrder.indexOf(b) ? 1 : -1;
                }
                return a > b ? 1 : -1;
            });
        }
    },
    methods: {
        processChange(event) {
            this.$emit('update:modelValue', event.target.value);
            this.$emit('selectedIndex', event.target.selectedIndex);
        }
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/colors.scss';

    .select-container {
        width: 225px;
    }

    .select-title {
        margin-top: 10px;
    }

    select {
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        // Additional resets for further consistency
        background-color: var(--color-bg);
        color: var(--color-contrast-full);
        border: none;
        padding: 5px;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
    }

    .select {
        margin: 10px 0 10px;
        width: 100%;
        min-width: 15ch;
        max-width: 30ch;
        border: 1px var(--color-contrast-high);
        border-radius: 0.25em;
        padding: 0.25em 0.25em 0.25em 0em;
        font-size: 1.5rem;
        cursor: pointer;
        line-height: 1.1;
        background-color: var(--color-bg);
        display: grid;
        grid-template-areas: "select";
        align-items: center;
        &::after {
            content: "";
            width: 0.8em;
            height: 0.5em;
            background-color: var(--color-contrast-high);
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            justify-self: end;
        }
    }

    select, .select:after {
        grid-area: select;
    }

</style>