export const matrixConfig = {
    columnHeader: 'Number of Passing Course Grades',
    rowHeader: 'ADA',
    columnFieldKey: 'passingCourseCount',
    rowFieldKey: '_ADA',
    columns: ['0-3', '4', '5', '6', '7', '8+'],
    rows: ['>95%', '90%-95%', '<90%'],
    labels: ['Off-Track', 'Almost On-Track', 'On-Track', 'On-Track & High ADA'],
    rowFilters: [
        {gte: 95},
        {lt: 95, gte: 90},
        {lt: 90},
    ],
    columnFilters: [
        {lt: 4},
        {lt: 5, gte: 4},
        {lt: 6, gte: 5},
        {lt: 7, gte: 6},
        {lt: 8, gte: 7},
        {gte: 8}
    ],
    cells: [ // calculated later
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0]
    ],
    cellClasses: [
        ['problem', 'warning', 'warning', 'high-success', 'high-success', 'high-success'],
        ['problem', 'warning', 'warning', 'success', 'success', 'success'],
        ['problem', 'warning', 'warning', 'success', 'success', 'success']
    ],
    showChart: true,
    chart: { // calculated later
        data: [],
        fields: [],
        config: {}
    }
};