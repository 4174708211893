export const apps = [
    {
        key: 'users',
        displayName: 'User Management',
        description: 'The User Management App allows authorized users to create and update users and permissions. It also shows the user a breakdown of usage statistics across all the apps.',
        icon: 'admin_panel_settings',
        applets: ['users', 'usage'],
        launchRoute: 'users',
        fields: null, 
        fieldGroups: null, 
        chatAvailable: false,
        availableGradeLevels: [],
        databases: ['events'],
        defaultGradeLevel: null,
        multiSchool: true,
        trackUsage: false
    },
];
