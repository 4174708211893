<template>
    <transition name="slide-fade">
        <Sidebar class="home-sidebar" v-if="store.state.ui.sidebarVisible">
            <img src="../assets/img/EHHS_sidebar.jpg">
        </Sidebar>
    </transition>
    <div :class="getMainAreaWidthClass" class="home-main-area">
        <div class="scroll-area-home">
            <h1 class="dashboard-header">Welcome to the RISE Web Apps</h1>
            <h2 class="dashboard-header">My Installed Apps</h2>
            <div class="app-holder-area">
                <div v-for="(app, index) in availableApps" :key="index" class="app-holder">
                    <span class="material-icons app-icon">{{ app.icon }}</span>
                    <div class="app-title">{{ app.displayName }}</div>
                    <div class="app-description">{{ app.description }}</div>
                    <CustomButton class="launch-app-button" @click="launch(app.key)" :buttonText="app.key==store.state.currentApp?'Return to App':'Launch'"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { installedApps } from '@/apps/apps.js';
import Sidebar from '@/components/sidebar.vue';
import { getMainAreaWidthClass } from '@/functions/utils.js';
import CustomButton from '@/components/customButton.vue';

export default {
    name: 'Home',
    data() {
        return {}
    },
    methods: {
        launch(key) {
            if (key==this.store.state.currentApp) {
                this.$router.push('/' + this.config.launchRoute);                
            } else {
                const whereToGo = this.session.launchApp(key);
                this.$router.push('/' + whereToGo);
            }
        }
    },
    computed: {
        getMainAreaWidthClass: function () {
            return getMainAreaWidthClass(this.store.state.ui.sidebarVisible, this.store.state.ui.chatVisible);
        },
        availableApps: function () {
            if (this.$user.roles.includes('superuser')) return installedApps;

            const myAppKeys = this.$user.availableApps;
            if (myAppKeys.includes('all')) {
                return installedApps.filter(app => app.key !== 'users');
            }
            return installedApps.filter(app => myAppKeys.includes(app.key));
        }
    },
    activated() {
        this.store.setChatVisible(false);
    },
    components: { Sidebar, CustomButton }
}
</script>

<style lang="scss">
    @import '../assets/styles/mystyles.scss';
    @import '../assets/styles/colors';

    .dashboard-header {
        margin: 20px;
    }

    .home-main-area {
        padding: 20px 0px 0px 20px !important;
    }

    .home-sidebar {
        overflow: hidden;
        height: 100%;
        img {
            height: calc(100vh);
            min-height: 750px;
        }
    }

    .app-holder-area {
        padding-bottom: 20px;
    }

    .app-holder {
        .app-icon {
            display: block;
            float: left;
            font-size: 55px;
            padding: 10px;
            background-color: var(--color-info-light);
            color: var(--color-contrast-full);
            border-radius: 15px;
            margin-right: 20px;
        }
        .launch-app-button {
            margin: 20px 0;
        }
        .app-title {
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 0.8rem;
        }

        border-style: solid;
        border-width: 1px;
        border-radius: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        padding: 20px 20px 0;
        border-color: var(--color-contrast-low);
        background-color: var(--color-contrast-lower);
    }

</style>