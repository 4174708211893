import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import { getAuth } from 'firebase/auth';

export const routes = [
    {
        path: '/tracker',
        name: 'Student Tracker',
        meta: {
            title: 'Student Tracker',
            key: 'tracker',
            hideHints: false,
            showInNavbar: true
        },
        userDescription: 'View the student tracker table.',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Tracker.vue')
    },
    {
        path: '/profile',
        name: 'Student Profile',
        meta: {
            title: 'Student Profile',
            key: 'profile',
            hideHints: true,
            showInNavbar: true
        },
        userDescription: 'View individual student profiles.',
        component: () => import(/* webpackChunkName: "about" */ '@/views/StudentProfile.vue')
    },
    {
        path: '/summarytables',
        name: 'Summary Tables',
        meta: {
            title: 'Summary Tables',
            key: 'summarytables',
            hideHints: true,
            showInNavbar: true
        },
        userDescription: 'View summary tables.',
        component: () => import(/* webpackChunkName: "about" */ '@/views/SummaryTables.vue')
    },
    {
        path: '/protocol',
        name: 'Protocol',
        meta: {
            title: 'Protocol',
            key: 'protocol',
            hideHints: true,
            showInNavbar: true
        },
        userDescription: 'View Protocols.',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Slides.vue')
    },
    {
        path: '/analytics',
        name: 'Analytics',
        meta: {
            title: 'Analytics',
            key: 'analytics',
            hideHints: true,
            showInNavbar: true
        },
        userDescription: 'Create your own report based on student data.',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Analytics.vue')
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            hideNavigation: true,
            key: 'login',
            hideHints: true,
            title: 'Login'
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            hideNavigation: false,
            key: 'home',
            hideHints: true,
            title: 'Home'
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from) => {
    const isLoggedIn = () => {
        const auth = getAuth();
        return auth.currentUser ? true : false;
    };

    if (to.name !== 'Login' && !isLoggedIn()) {
        return '/';
    } else {
        document.title = to.meta.title;
        return true;
    }
});

export default router

    // {
    //     path: '/users',
    //     name: 'User Management',
    //     meta: {
    //         title: 'User management',
    //         key: 'users',
    //         hideHints: true,
    //         showInNavbar: false
    //     },
    //     userDescription: 'User management.',
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/Users.vue')
    // },
    // {
    //     path: '/usage',
    //     name: 'Usage',
    //     meta: {
    //         title: 'Usage',
    //         key: 'usage',
    //         hideHints: true,
    //         showInNavbar: false
    //     },
    //     userDescription: 'View usage statistics.',
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/Usage.vue')
    // },