export const matrixConfig = {
    columnHeader: 'Current Year GPA',
    rowHeader: 'Attendance',
    columnFieldKey: 'g8GPA',
    rowFieldKey: '_ADA',
    columns: ['0.0-0.99', '1.0-1.99', '2.0-2.99', '3.0-4.0'],
    rows: ['>98%', '95%-98%', '90-95%', '80-90%', '<80%'],
    labels: ['High Risk', 'Vulnerable', 'Opportunity', 'High Opportunity'],
    rowFilters: [
        {gte: 98},
        {lt: 98, gte: 95},
        {lt: 95, gte: 90},
        {lt: 90, gte: 80},
        {lt: 80},
    ],
    columnFilters: [
        {lt: 1},
        {lt: 2, gte: 1},
        {lt: 3, gte: 2},
        {gte: 3}
    ],
    cells: [ // calculated later
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0]
    ],
    cellClasses: [
        ['warning', 'warning', 'success', 'high-success'],
        ['problem', 'warning', 'warning', 'high-success'],
        ['problem', 'problem', 'warning', 'success'],
        ['problem', 'problem', 'problem', 'warning'],
        ['problem', 'problem', 'problem', 'warning'],
    ],
    showChart: true,
    chart: { // calculated later
        data: [],
        fields: [],
        config: {}
    }
};