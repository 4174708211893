import { blackOrWhite } from '@/functions/utils.js';

export const formatters = {
    nullWarning(colors) {
        return `<span title="No data" style="color: ${colors.contrastLow};" class="material-icons">pending</span>`;
    },
    badgify(value, test, colors) {
        if (value === null) return this.nullWarning(colors);
        const color = test ? colors.success : colors.contrastHigh;
        return `<span class="badge" style="color: ${colors.bg}; background-color: ${color};"> ${value} </span>`;
    },
    trafficLight(value, testArray, colors) {
        if (value === null) return this.nullWarning(colors);

        const passingTest = testArray.find(element => element.test);
        const colorName = passingTest?.colorName || 'contrastHigh';
        const BGColorName = passingTest?.BGcolorName || 'bg';

        return `<span class="badge" style="color: ${colors[BGColorName]}; background-color: ${colors[colorName]};"> ${value} </span>`;
    },
    warnify(test, colors) {
        return test ? `<span style="color: ${colors.warning};" class="material-icons">warning</span>` : '';
    },
    checkify(bool, colors) {
        if (bool === null) return this.nullWarning(colors);
        return bool ? 
            `<span class="material-icons-outlined" style="color: ${colors.success}">task_alt</span>`:
            `<span class="material-icons-outlined" style="color: ${colors.contrastHigher}">circle</span>`;
    },
    stepify(value, colors) {
        if (value === null) return this.nullWarning(colors);
        const option = [
            {
                status: 'not started',
                icon: 'circle',
                color: colors.contrastHigher
            },
            {
                status: 'in progress',
                icon: 'pending',
                color: colors.info
            },
            {
                status: 'completed',
                icon: 'task_alt',
                color: colors.success
            },
            {
                status: 'N/A',
                icon: 'task_alt',
                color: colors.info
            },
        ].find(element => element.status == value);

        return option ? `<span class="material-icons-outlined" style="color: ${option.color}"> ${option.icon} </span>` : '';
    },
    activate(value, colors) {
        if (value === null) return this.nullWarning(colors);
        const option = [
            {
                status: false,
                icon: 'circle',
                color: colors.contrastHigher
            },
            {
                status: true,
                icon: 'task_alt',
                color: colors.success
            },
        ].find(element => element.status == value);

        return option ? `<span class="material-icons-outlined" style="color: ${option.color}"> ${option.icon} </span>` : '';
    }
};

const formatNotesCell = (cell, notes) => {
    return notes.some(note => note.studentID == cell._cell.row.data.studentRISEId.toString()) ?
        '<span data-cy="tracker-chat-icon" class="material-icons">chat</span>':
        '<span data-cy="tracker-chat-icon" class="material-icons-outlined">chat_bubble_outline</span>';
};

const formatTagsCell = (cell, tags) => {
    const value = cell.getValue();

    if (!value?.[0]) return '';

    const myTags = tags.filter(tag => value[0].value.includes(tag.docID));

    const htmlContent = myTags
        .map(tag => `<span class="badge" style="background-color: #${tag.color}; color: ${blackOrWhite(tag.color)};">${tag.title}</span>`)
        .join(' ');

    const myTitle = myTags.map(tag => tag.title).join(', ');

    return `<span title="${myTitle}">${htmlContent}</span>`;
};

const formatCellWithVictory = (cell, field, colors) => {
    const victory = field.victory || (() => true);
    return field.formatter(cell, victory, colors);
};

export const formatCell = ({ // set formatters for every cell in tracker, taking into account victory conditions
    cell, field, colors, notes, tags
}) => {
    // notes column
    if (field.type == 'notes') return formatNotesCell(cell, notes);

    // tags column
    if (field.type == 'tags') return formatTagsCell(cell, tags);

    // cells with formatter set in field.js
    if (typeof field.formatter == 'function')  return formatCellWithVictory(cell, field, colors);

    // show warning if value is null
    if (cell.getValue() === null) return formatters.nullWarning(colors);

    // if no formatter is specified, just return original value
    return cell.getValue();
};


