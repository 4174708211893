export const roles = [
    {
        key: 'PST_supportStaff',
        app: 'PST',
        displayName: 'Support Staff',
        powers: [
            'viewChat',
            'contributeToChat',
        ]
    },
    {
        key: 'PST_counselor',
        app: 'PST',
        displayName: 'Counselor',
        powers: [
            'viewChat',
            'contributeToChat',
            'assignTags',
            'updateAllStudents' // temporary until caseloads are implemented
        ]
    },
    {
        key: 'PST_leadCounselor',
        app: 'PST',
        displayName: 'Lead Counselor',
        powers: [
            'viewDataByCounselor',
            'viewChat',
            'contributeToChat',
            'assignTags',
            'updateAllStudents'
        ]
    },
    {
        key: 'PST_schoolAdmin',
        app: 'PST',
        displayName: 'School Administrator',
        powers: [
            'viewDataByCounselor',
            'viewChat',
            'contributeToChat',
        ]
    },
    {
        key: 'PST_districtAdmin',
        app: 'PST',
        displayName: 'District Administrator',
        powers: [
            'viewDataByCounselor'
        ]
    },
    {
        key: 'pstAdmin_viewer',
        app: 'pstAdmin',
        displayName: 'PST Admin User',
        powers: [
            'viewDataByCounselor'
        ]
    },
    {
        key: 'G8T_viewer',
        app: 'G8T',
        displayName: 'R&O User',
        powers: []
    },
    {
        key: 'riseStaff',
        app: 'all',
        displayName: 'RISE Staff',
        powers: [
            'viewDataByCounselor',
            'viewChat',
            'contributeToChat',
            'assignTags',
            'manageTags',
            'updateAllStudents'
        ]
    },
    {
        key: 'superuser',
        app: 'all',
        displayName: 'Developer'
    }
];

/**
 *     {
        key: 'usage_viewer',
        app: 'usage',
        displayName: 'Network Wide App Usage viewer',
        powers: []
    },    
    {
        key: 'schoolUsage_viewer',
        app: 'schoolUsage',
        displayName: 'School level App Usage viewer',
        powers: []
    },
    {
        key: 'userManager',
        app: 'users',
        displayName: 'User Manager',
        powers: []
    },
 */


export const powers = [
    {
        key: 'viewDataByCounselor',
        description: 'Can view disaggregated student data by counselor'
    },
    {
        key: 'viewChat',
        description: 'Can view chat messages'
    },
    {
        key: 'contributeToChat',
        description: 'Can create, update, and delete own chat messages'
    },
    {
        key: 'assignTags',
        description: 'Can assign and unassign tags to students'
    },
    {
        key: 'manageTags',
        description: 'Can create, update, and delete tags'
    },
    {
        key: 'updateAllStudents',
        description: 'Can update student data for all students at permitted schools'
    }
];
