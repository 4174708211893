import { defaultViews } from '@/apps/pst/views.js';
import { fields, fieldGroups } from '@/apps/pst/fields.js';

export const apps = [
    {
        key: 'PST',
        displayName: 'Postsecondary Tracker',
        description: 'The Postsecondary Tracker helps Postsecondary teams track the postsecondary plans and necessary preparation of students in Grades 11 and 12.',
        icon: 'bar_chart',
        applets: ['tracker', 'profile', 'summarytables', 'analytics'],
        launchRoute: 'tracker',
        trackerConfig: {
            showViews: true,
            defaultView: 'Academic Performance',
            defaultViews,
        },
        analyticsConfig: {
            rowGroup: 'plans',
            columnGroup: 'gender',
            redirectDefaultFields: [
                '_studentName',
                'counselorName',
                'plans'
            ]
        },
        profileConfig: {
            showCourses: false
        },
        fields, 
        fieldGroups, 
        chatAvailable: true,
        availableGradeLevels: [11, 12],
        databases: ['main', 'notes', 'tags'],
        defaultGradeLevel: 12,
        multiSchool: false,
        trackUsage: true
    },
    {
        key: 'pstAdmin',
        displayName: 'Postsecondary Admin',
        description: 'The Postsecondary Admin App helps Postsecondary leadership analyze trends across schools.',
        icon: 'settings',
        applets: ['analytics'],
        launchRoute: 'analytics',
        analyticsConfig: {
            rowGroup: 'plans',
            columnGroup: 'gender'
        },
        fields, 
        fieldGroups, 
        chatAvailable: false,
        availableGradeLevels: [11, 12],
        databases: ['main'],
        defaultGradeLevel: 12,
        multiSchool: true,
        trackUsage: false
    }
];