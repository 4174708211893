/* eslint no-unused-vars: "off" */

<template>
    <div id="app-container">
        <ul class="navbar" data-cy="navbar" v-if="!$route.meta.hideNavigation">
            <li>
                <router-link class="rise-home-anchor" to="/home">
                    <img data-cy="rise-logo" src="@/assets/img/rise_logo_vertical_sm.png" alt="RISE Logo">
                </router-link>
            </li>
            <li v-for="(route, index) in store.state.routes" :key="index">
                <router-link 
                    @mouseover="showTooltip(route.userDescription)"
                    :to="route.path"
                    v-if="shouldShowRoute(route)"
                >
                    {{ route.name }}
                </router-link>
            </li>
            <li class="navbar-current-school" v-if="notOnHomePage()">
                <div
                    :class="getChangeModeClass()"
                    data-cy="change-school-or-grade"
                    @mouseover="showTooltip('Change school or grade')"
                    @click="launchChangeMode()"
                >
                    {{ getSchool() }}&nbsp;
                    <span class="current-grade" 
                        v-if="config.availableGradeLevels?.length"
                    >
                        Grade&nbsp;{{ getGrade() }}
                    </span>
                </div>
            </li>
            <li style="float:right">
                <span class="views-area">
                    <span 
                        @mouseover="showTooltip('Show or hide the options sidebar')"
                        class="view-icon" 
                        :class="store.state.ui.sidebarVisible ? 'active' : 'inactive'" 
                        :title="toggleSidebarTooltip"
                        @click="toggleSidebarVisible"
                        data-cy="toggle-sidebar"
                    >
                        <span class="material-icons">view_sidebar</span>
                    </span>
                    <span 
                        @mouseover="showTooltip('Show or hide the chat sidebar')"
                        class="view-icon nudge-down" 
                        :class="store.state.ui.chatVisible ? 'active' : 'inactive'" 
                        :title="toggleChatTooltip" 
                        @click="toggleChatVisible"
                        v-if="shouldShowChat()"
                        data-cy="toggle-chat"
                    >
                        <span class="material-icons">chat</span>
                    </span>
                    <div class="avatar-area view-icon">
                        <span 
                            data-cy="initials"
                            class="initials"
                            @mouseover="showTooltip('Logged in as ' + getUserName())"
                        >
                            {{ getInitials() }}
                        </span>
                        <div class="dropdown-area settings-dropdown">
                            <div class="dropdown-inner">
                                <div data-cy="open-settings" class="dropdown-item" @mouseover="showTooltip('Open view settings')" @click="openSettings">Settings</div>
                                <div data-cy="logout" class="dropdown-item" @mouseover="showTooltip('Log out')" @click="logOut">Logout</div>
                            </div>
                        </div>
                    </div>
                </span>
            </li>
        </ul>
        <div id="main-area">
            <div data-cy="tip-area" id="tip-area" @mouseover="setHintsDraggable" v-if="store.state.ui.showHints && !$route.meta.hideHints">
                <div id="tip-area-header">Info<div class="hide-hints" @click="showHints(false)">✖</div></div>
                <div id="tip-area-body">
                    {{ tooltipText || 'Hover your cursor over different parts of the app to see information appear in this box.' }}
                </div>
            </div>
            <Modal data-cy="edit-field" ref="editField" title="Edit Entry" :instructions="modals.editFieldInstructions" @success="updateDataFromModal">
                <input v-if="editFieldInputToShow=='text'" v-model="modals.editField" placeholder="Type here">
                <input v-if="editFieldInputToShow=='number'"  type="number" v-model="modals.editField">
                <MultipleChoice v-if="editFieldInputToShow=='selectMultiple'" :options="modals.editCategories" v-model="modals.editField" />
                <CustomSelect v-if="editFieldInputToShow=='select'" :options="modals.editCategories" displayField="displayName" v-model="modals.editField" />
            </Modal>
            <Modal data-cy="settings-modal" ref="settings" title="Settings" instructions="" @success="updateSettings">
                <p>Theme</p>
                <CustomSelect data-cy="theme-setting" :options="store.state.ui.loadedThemes" displayField="displayName" v-model="modals.colorTheme" />
                <p>Row Width</p>
                <CustomSelect data-cy="row-width-setting" :options="rowWidths" displayField="displayName" v-model="modals.rowWidth" />
                <p>Default School</p>
                <CustomSelect :options="userSchools" displayField="displayName" v-model="modals.defaultSchool" />
                <div data-cy="show-hints-setting">
                    <input type="checkbox" id="showHintsCheckbox" v-model="modals.showHints">
                    <label for="showHintsCheckbox">Show info box on start</label>
                </div>
            </Modal>
            <Modal data-cy="change-mode-modal" ref="changeMode" title="Change Mode" instructions="" @success="updateMode">
                <div v-if="userCanChangeSchool()">
                    <p>School</p>
                    <CustomSelect data-cy="school-setting" :options="availableSchools" displayField="displayName" v-model="modals.school" />
                </div>
                <div v-if="userCanChangeGrade()">
                    <p>Grade</p>
                    <CustomSelect data-cy="grade-setting" :options="availableGrades" displayField="displayName" v-model="modals.grade" />
                </div>
            </Modal>
            <Modal data-cy="idleWarning" ref="idleWarning" :title="'Still here?'" :showButtons="false">
                <p> Are you still using this app? </p>
                <p> Move your mouse to prevent logout. </p>
                <p> Logout will occur in {{ logOutTimer }} seconds. </p>
            </Modal>
            <TagManager ref="tagManager" v-if="!$route.meta.hideNavigation" :studentID="tagStudentID"/>
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component ref="currentRoute" :is="Component" />
                </keep-alive>
            </router-view>

            <transition name="slide-fade-right">
                <Chat v-if="store.state.ui.chatVisible && !$route.meta.hideNavigation" :student="chatStudent" @clearFilters="clearChatFilters" />
            </transition>
        </div>
    </div>
</template>

<script>
import Chat from '@/components/chat.vue';
import TagManager from '@/components/tagManager.vue';
import Modal from '@/components/modal.vue';
import CustomSelect from '@/components/customSelect.vue';
import MultipleChoice from '@/components/multipleChoice.vue';
import { dragElement, getGroupValue } from '@/functions/utils.js';
import { preferences } from '@/core/user.js';

export default {
    data() {
        return {
            chatStudent: {},
            tagStudentID: '',
            modals: {
                editFieldInstructions: '',
                editField: '',
                editCategories: [],
                colorTheme: 'Default theme',
                rowWidth: 'Default',
                showHints: true,
                school: '', // current school
                grade: '', // current grade
                defaultSchool: ''
            },
            availableSchools: [],
            availableGrades: [],
            editFieldInputToShow: 'text',
            fieldBeingEdited: {},
            rowWidths: preferences.find(e => e.key == 'rowWidth').options,
            logOutTimer: 30,
            tooltipText: '',
            userSchools: [],
            preferences
        }
    },
    methods: {
        setEventListeners() {
            [
                {
                    name: 'loggedIn',
                    fn: () => this.setSchoolOptions()
                },
                {
                    name: 'openChat',
                    fn: student => this.openChat(student)
                },
                {
                    name: 'editField',
                    fn: obj => this.launchEditField(obj)
                },
                {
                    name: 'openTagManager',
                    fn: studentID => this.openTagManager(studentID)
                },
                {
                    name: 'updateRecord',
                    fn: obj => this.updateRecord({fieldkey: obj.field, id: obj.id, value: obj.value})
                },
                {
                    name: 'userLoggedOut',
                    fn: () => this.$router.push('/')
                },
                {
                    name: 'userIdle',
                    fn: () => this.showIdleModal()
                },
                {
                    name: 'userNotIdle',
                    fn: () => this.setNotIdle()
                },
                {
                    name: 'showTooltip',
                    fn: str => {
                        this.tooltipText = str;
                    }
                },
            ].forEach(event => {
                this.ee.on(event.name, event.fn);
            });
        },
        setSchoolOptions() {
            const mySchools = this.store.state.schools
                .filter(school => this.$user.schools.includes(school.key))
                .map(school => ({
                    displayName: school.displayName,
                    value: school.key
                }));
            this.preferences.find(p => p.key == 'defaultSchool').options = mySchools;
            this.userSchools = mySchools;
        },
        openSettings() {
            this.preferences.forEach(element => {
                if (this.$user.preferences[element.key] == undefined) return;

                if (element.type == 'category') {
                    const obj = element.options.find(e => e.value == this.$user.preferences[element.key]);
                    this.modals[element.key] = obj ? obj.displayName : element.default;
                } else if (element.type == 'boolean') {
                    this.modals[element.key] = this.$user.preferences[element.key];
                }
            });

            this.$refs.settings.show();
        },
        updateSettings() {
            this.preferences.forEach(element => {
                const value = element.type == 'category' ?
                    element.options.find(e => e.displayName == this.modals[element.key])?.value:
                    this.modals[element.key];

                if (value) {
                    if (element.setFn) {
                        this.store[element.setFn](value);
                    }
                    this.fb.user.updatePreference(element.key, value);
                }
            });
            this.fb.user.updatePreference('showHints', this.modals.showHints);
            this.showHints(this.modals.showHints);
        },
        showHints(bool = true) {
            this.modals.showHints = bool;
            this.store.setHintsVisible(bool);
        },
        startTimer() {
            this.logOutTimer = 30;
             
            this.logOutTimerId = setInterval(() => {
                this.logOutTimer -= 1;
                if (this.logOutTimer < 1) {
                    this.expelUser();
                }
            }, 1000);  // 1 second
        },
        clearTimer() {
            if (!this.logOutTimerId) return;
            clearInterval(this.logOutTimerId);
        },
        expelUser() {
            this.clearTimer()
            this.hideIdleModal();
            this.logOut();
        },
        showIdleModal() {
            this.$refs.idleWarning.show();
            this.startTimer();
        },
        hideIdleModal() {
            this.$refs.idleWarning.hide();
        },
        setNotIdle() {
            this.hideIdleModal();
            this.clearTimer();
        },
        logOut() {
            this.session.processLogOut();
        },
        toggleChatVisible() {
            if (!this.config.chatAvailable) return;
            this.store.toggleChatVisible();
        },
        toggleSidebarVisible() {
            this.store.toggleSidebarVisible();
        },
        openChat(obj) {
            /**
             * obj.id - student's studentRISEId
             * obj.name - student name in form Sarah Jones
             */
            if (!this.config.chatAvailable) return;
            this.chatStudent = obj;
            this.store.setChatVisible(true);
        },
        clearChatFilters() {
            this.chatStudent = {};
        },
        launchEditField({
            key, // field key
            currentValue, // current value of data
            id, // studentRISEId of record to be changed
            studentName // display name of record to be changed
        }) {

            const myField = this.config.fields.find(field => field.key == key);

            // hold the data of field to be edited
            this.fieldBeingEdited = { id, key };

            // decide what kind of input to show

            this.editFieldInputToShow = [
                {type: 'categoryArray', show: 'selectMultiple'},
                {type: 'category', show: myField.options ? 'select' : 'text'},
                {type: 'numeric', show: 'number'},
                {type: 'boolean', show: 'select'},
                {type: 'unique', show: 'text'},
            ].find(element => element.type == myField.type)?.show;

            if (!this.editFieldInputToShow) return;

            // populate the options available
            if (this.editFieldInputToShow == 'select') {
                this.modals.editCategories = Object.entries(myField.options)
                    .map(element => (
                        {displayName: element[1]}
                    ));
            } else if (this.editFieldInputToShow == 'selectMultiple') {
                this.modals.editCategories = Object.entries(myField.options)
                    .map(element =>  element[1]);
            };

            // populate the existing value
            this.modals.editField = (this.editFieldInputToShow == 'select') ? 
                myField.options[currentValue] :
                currentValue;

            // set the instructions in modal window
            this.modals.editFieldInstructions = `Set ${myField.displayName} for ${studentName}`;

            // show the modal window
            this.$refs.editField.show();
        },
        updateDataFromModal() { 
            this.updateRecord({
                fieldkey: this.fieldBeingEdited.key, 
                id: this.fieldBeingEdited.id, 
                value: getGroupValue({
                    field: this.config.fields.find(f => f.key == this.fieldBeingEdited.key),
                    value: this.modals.editField
                })
            });
        },
        updateRecord({fieldkey, id, value}) {
            // make a clone to avoid mutating the store here
            const myRecord = Object.assign({}, this.store.state.studentData.find(record => record.studentRISEId == id)); 

            // add a log to the array of changes to this field
            myRecord[fieldkey].unshift({
                value: value,
                timestamp: (new Date).valueOf(),
                author: this.$user.uid,
                displayName: this.$user.displayName
            });

            // this.fb.events.add({ action: 'update', target: 'studentData' });

            // update firestore
            this.fb.studentData.update(myRecord.docID, myRecord, fieldkey);
        },
        openTagManager(id) {
            this.tagStudentID = id;
            this.$refs.tagManager.show();
        },
        shouldShowRoute(route) {
            if (this.$router.currentRoute._value.name == 'Home') return false;
            if (!this.config.applets) return false;
            return this.config.applets.includes(route.meta.key);
        },
        shouldShowChat() {
            if (this.$router.currentRoute._value.name == 'Home') return false;
            if (!this.$user) return false;
            if (!this.config.chatAvailable) return false;
            return this.$user.hasPower('viewChat');
        },
        notOnHomePage() {
            return this.$router.currentRoute._value.name !== 'Home';
        },
        getUserName() {
            return this.$user ?
                this.$user.displayName:
                '';
        },
        getInitials() {
            return this.$user ?
                this.$user.initials:
                '';
        },
        getSchool() {
            if (!this.$user) return '';
            if (this.config.multiSchool) return 'Multiple schools';
            return this.store.state.schools.find(school => school.key == this.store.state.chosenSchool)?.displayName || '';
        },
        getGrade() {
            if (!this.$user) return '';
            return this.store.state.showingGradeLevel;
        },
        userCanChangeSchool() {
            if (this.config.multiSchool) return false;
            if (!this.$user) return false;
            return this.$user.schools.length > 1;
        },
        userCanChangeGrade() {
            if (!this.$user) return false;
            if (this.config.availableGradeLevels?.length < 2) return false;
            return this.$user.gradeLevel.length > 1;
        },
        setHintsDraggable() {
            dragElement(document.getElementById("tip-area"));
        },
        getChangeModeClass() {
            return (this.userCanChangeSchool() || this.userCanChangeGrade()) ?
                'current-school-and-grade':
                'multi-school-no-swap'
        },
        launchChangeMode() {
            if (!this.userCanChangeSchool() && !this.userCanChangeGrade()) return;
            this.availableSchools = this.store.state.schools.filter(e => this.$user.schools.includes(e.key));
            this.availableGrades = this.$user.gradeLevel
                .filter(e => this.config.availableGradeLevels.includes(e))
                .map(e => ({displayName: e.toString()}));
            this.modals.school = this.getSchool();
            this.modals.grade = this.store.state.showingGradeLevel?.toString() || '';
            this.$refs.changeMode.show();
        },
        updateMode() {
            const grade = Number(this.modals.grade);
            const schoolKey = this.store.state.schools.find(e => e.displayName == this.modals.school)?.key;

            if (!schoolKey && !this.config.multiSchool) {
                console.error('School not found');
                return;
            }

            if (grade !== this.store.state.showingGradeLevel || this.modals.school !== this.getSchool()) {
                this.session.resubscribe({grade, schoolKey});
            }
        },
    },
    computed: {
        toggleSidebarTooltip: function () {
            return this.store.state.ui.sidebarVisible ? 'Hide sidebar' : 'Show sidebar';
        },
        toggleChatTooltip: function () {
            return this.store.state.ui.chatVisible ? 'Hide chat' : 'Show chat';
        },
    },
    components: {
        Chat, Modal, CustomSelect, TagManager, MultipleChoice
    },
    mounted() {
        this.setEventListeners();
    },
}
</script>

<style lang="scss">
  @import '/assets/styles/mystyles.scss';
</style>
