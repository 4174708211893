import { fields } from '@/apps/pst/fields.js';

const getPlanFilter = str => [
    {
        displayName: 'Active Plan', 
        field: 'plans',
        type: 'customEquals',
        displayType: ':',
        value: str
    }
];

export const defaultViews = [
    {
        name: 'All Fields',
        fields: [
            'counselorName',
            'tags',
            'school.district',
            'englishLearner',
            'gender',
            'raceAndEthnicity.race',
            'school.name',
            'specialEducation',
            'firstGeneration',
            'email',
            'currentGradeLevel',
            'studentDistrictId',
            '_studentName',
            '_ADA',
            '_GPABand',
            'graduationConcerns',
            'lastNavianceLogin',
            '_predictedCredits',
            'SATScores.EBRW',
            'SATScores.Math',
            'creditsEarned',
            'transcriptsSent',
            '_collegeAppsSubmitted',
            '_4YearStepsDone',
            'collegeApps',
            'FAFSAStatus',
            'FSAId',
            'FAFSAStateStatus',
            'immunizationForm',
            'intendedMajor',
            'collegeCommitment',
            'navianceCollegeList',
            'studentRequestedRecs',
            'teacherSubmittedRecs',
            'ASVAB',
            'careerInventory',
            'employment',
            'jobApplications',
            'militaryBranchCommitment',
            'militaryBranchInterest',
            'militaryRecruiter',
            'resume',
            'tradeProgramEnrollment',
            'workPermit',
            'plans',
            // 'postSecondaryRegistration',
            // 'postSecondaryOrientation',
        ]
    },
    {
        name: 'Academic Performance',
        fields: [
            '_studentName',
            'tags',
            'counselorName',
            '_GPABand',
            'intendedMajor',
            'graduationConcerns',
            'lastNavianceLogin',
            'creditsEarned',
            'SATScores.EBRW',
            'SATScores.Math',
            'plans'
        ]
    },
    {
        name: 'FAFSA',
        fields: [
            '_studentName',
            'tags',
            'intendedMajor',
            'studentDistrictId',
            'counselorName',
            'plans',
            'FAFSAStateStatus',
            'FAFSAStatus',
            'FSAId'
        ]
    },
    {
        name: '4-Year College',
        filters: getPlanFilter('4-Year College'),
        fields: [
            'counselorName',
            'tags',
            'intendedMajor',
            '_4YearStepsDone',
            'collegeCommitment',
            '_studentName',
            'graduationConcerns',
            '_collegeAppsSubmitted',
            'collegeApps',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'navianceCollegeList',
            'studentRequestedRecs',
            'teacherSubmittedRecs',
            'plans'
        ]
    },
    {
        name: '2-Year College',
        filters: getPlanFilter('2-Year College'),
        fields: [
            'counselorName',
            'tags',
            'intendedMajor',
            '_studentName',
            'graduationConcerns',
            '_collegeAppsSubmitted',
            'collegeApps',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'immunizationForm',
            'navianceCollegeList',
            'studentRequestedRecs',
            'teacherSubmittedRecs',
            'plans'
        ]
    },
    {
        name: 'Military',
        filters: getPlanFilter('Military'),
        fields: [
            'counselorName',
            'tags',
            'intendedMajor',
            '_studentName',
            'graduationConcerns',
            'FAFSAStatus',
            'ASVAB',
            'militaryBranchCommitment',
            'militaryBranchInterest',
            'militaryRecruiter',
            'plans'
        ]
    },
    {
        name: 'Workforce',
        filters: getPlanFilter('Workforce'),
        fields: [
            'counselorName',
            'tags',
            'plans',
            'intendedMajor',
            '_studentName',
            'graduationConcerns',
            'collegeApps',
            'FAFSAStatus',
            'careerInventory',
            'jobApplications',
            'resume',
            'workPermit'
        ]
    },
    {
        name: 'Trade or Technical',
        filters: getPlanFilter('Trade or Technical'),
        fields: [
            'counselorName',
            'tags',
            'plans',
            'intendedMajor',
            '_studentName',
            'collegeApps',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'careerInventory',
            'tradeProgramEnrollment',
            'workPermit'
        ]
    },
    {
        name: 'Matriculation',
        filters: [],
        fields: [
            '_studentName',
            'studentDistrictId',
            'counselorName',
            'tags',
            'plans',
            'collegeCommitment',
            'transcriptsSent',
            'FAFSAStatus',
            'FAFSAStateStatus',
            'firstGeneration',
            'immunizationForm',
            'postSecondaryRegistration',
            'postSecondaryOrientation',
            '_collegeAppsSubmitted'    
        ]
    },
    {
        name: 'Juniors',
        filters: [],
        fields: [
            '_studentName',
            'counselorName',
            'tags',
            'plans',
            'firstGeneration',
            'alternatePlans',
            'intendedMajor',
            'summerCollegePrepInterest',
            'FAFSAIntention',
            'noFAFSAIntentionExplanation',
            'FSAId'
        ]
    },
].map(element => ({
    name: element.name,
    filters: element.filters,
    fields: element.fields.map(field => fields.find(e => e.key == field).displayName)
}));
