export const getValueFromObject = (record, key) => {
    // return value from object using key that may include a .
    // for example, school.name would return the value from {school: {name: value}}
    if (!key) return '';
    if (!key.includes('.')) {
        if (record[key] == null) return null;
        if (typeof record[key] == 'object') {
            return record[key][0]?.value;
        }
        return record[key];
    }
    const keypath = key.split('.');
    return record[keypath[0]][keypath[1]];
};

export const processTrueFalse = val => val == 'true' ?
    true:
    val == 'false' ?
        false:
        val;

export const getGroupValue = ({field, value}) => {
    if (value === null) return value;
    if (value === 'No data') return null;
    if (!field.options || field.type === 'categoryArray') return value;
    return processTrueFalse(
        Object.keys(field.options).find(key => field.options[key] == value)
    );
};

export const distinctSet = ({fieldKey, data}) => [...new Set(data.map(record => getValueFromObject(record, fieldKey)))];

export const orderSet = ({a, b, dataMissingWarning=null, field}) => {
    if (a === null || a === undefined || a === dataMissingWarning) return 1;
    if (b === null || b === undefined || b === dataMissingWarning) return -1;
    if (!field.order || !Array.isArray(field.order)) return a > b ? 1 : -1;
    return field.order.indexOf(a) > field.order.indexOf(b) ? 1 : -1;
};

export const getDisplayValue = ({value, field}) => { 
    /* shows the field category display name when it is different to the underlying data
    * for example the data are true/false and the display value is 'ELL', 'Not ELL'
    */
    const dataMissingWarning = 'No data';
    if (value === null || value === undefined) return dataMissingWarning;
    if (!field.options) return value.toString();
    return field.options[value];
};

export const getMainAreaWidthClass = (sidebarVisible, chatVisible) => {
    // return the CSS class for the main area of the app, depending on what elements are visible
    if (sidebarVisible) {
        if (chatVisible) {
            return 'main-area-very-narrow main-area-indented';
        } else {
            return 'main-area-narrow main-area-indented';
        }
    } else {
        if (chatVisible) {
            return 'main-area-narrow';
        } else {
            return 'main-area-full';
        }
    }
};

export const blackOrWhite = color => {
    // returns black if the color is light, white if the color is dark
    const r = parseInt(color.substr(0, 2), 16),
          g = parseInt(color.substr(2, 2), 16),
          b = parseInt(color.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return brightness > 140 ? 'black' : 'white';
};

export const dragElement = elmnt => {
    // from https://www.w3schools.com/howto/howto_js_draggable.asp
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "-header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "-header").onmousedown = dragMouseDown;
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
    }
    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }
    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }
    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }
}

export const isEnrolled = record => {

    // this section filters out unenrolled students.

    if (record.currentGradeLevel == 8) return true;
    
    const status = record.highSchoolEnrolledStatus;
    if (!status) {
        console.error('Student is missing highSchoolEnrolledStatus data');
        return false;
    }
    if (!Array.isArray(status)) {
        console.error('highSchoolEnrolledStatus field has unexpected data type');
        return false;
    }
    if (!status[0]) {
        console.error('highSchoolEnrolledStatus field has unexpected log entry');
        return false;
    }
    if (!status[0].value == undefined) {
        console.error('highSchoolEnrolledStatus field has unexpected value');
        return false;
    }
    if(typeof status[0].value !== 'boolean') {
        console.error('highSchoolEnrolledStatus field is not a boolean')
        return false;
    }

    return status[0].value;
};

export const processFieldsAndGroups = (fields, groups) => {

    fields.forEach(f => {
        const group = groups.find(g => g.fields.includes(f.key))
        f.optionGroup = group?.name || null;
    });
    
    groups.forEach(group => {
        group.fields = group.fields.map(element => ({
            name: fields.find(e => e.key == element).displayName, 
            key: element, checked: true
        }));
    });

    return [fields, groups];
};

export const getFirst = cell => {
    if (!cell.getValue()) {
        console.error('Error, value missing. Inspect the console stack trace to find which field has missing value', cell);
        return null;
    }
    return cell.getValue()[0].value;
};

export const mySort = (a, b) => { // sort by first value in array

    if (a[0].value < b[0].value) return -1;
    if (a[0].value > b[0].value) return 1;

    return 0;
};

export function customHeaderFunc (headerValue, rowValue, rowData, filterParams) {
    if (!headerValue) return true;
    if (Array.isArray(headerValue) && headerValue[0] == '') return true;
    return headerValue == rowValue;
}