import { fields } from '@/apps/g8t/fields.js';

export const defaultViews = [
    {
        name: 'Default',
        filters: [],
        fields: [
            '_studentName',
            'middleSchool',
            'gender',
            'raceAndEthnicity.race',
            'englishLearner',
            'specialEducation',
            'g8RiskAndOpportunity',
            '_ADA',
            'g8GPA',
        ]
    }
].map(element => ({
    name: element.name,
    filters: element.filters,
    fields: element.fields.map(field => fields.find(e => e.key == field).displayName)
}));
