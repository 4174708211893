/* eslint no-unused-vars: "off" */

require('firebase/auth');
import 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, query, onSnapshot, addDoc, getDoc, doc, deleteDoc, setDoc, updateDoc, collectionGroup, where } from "firebase/firestore";
import { validate } from '@/firebase/validate.js';
import { firebaseConfig } from '@/firebase/environments.js';
import { currentEnvironment } from '@/firebase/environments';
import studentSchema from '@/schemas/studentSchema.json';
import notesSchema from '@/schemas/notesSchema.json';
import userSchema from '@/schemas/userSchema.json';

// Initialize Firebase

const _app = initializeApp(firebaseConfig);
export const db = getFirestore(_app);

// Firebase plugin

export const installFB = (app, options) => {

    const databases = currentEnvironment.databases;
    const schemas = {
        main: studentSchema,
        notes: notesSchema,
        user: userSchema
    };

    const getPath = () => [
        db, 
        currentEnvironment.schoolCollection, 
        app.config.globalProperties.store.state.chosenSchool
    ];

    app.config.globalProperties.fb = {
        dataSources: [
            {
                key: 'main',
                database: databases.main,
                unsubscribeFn: 'unsubscribe',
                setDataFn: 'setStudentData'
            },
            {
                key: 'notes',
                database: databases.notes,
                unsubscribeFn: 'unsubscribeNotes',
                setDataFn: 'setNotes'
            },
            {
                key: 'tags',
                database: databases.tags,
                unsubscribeFn: 'unsubscribeTags',
                setDataFn: 'setTags'
            },
            // Usage tracking events.
            // { 
            //     key: 'events',
            //     database: databases.events,
            //     unsubscribeFn: 'unsubscribeEvents',
            //     setDataFn: 'setEvents'
            // },
        ],
        getQuery({
            source,
            multiSchool = app.config.globalProperties.config.multiSchool,
            userDistrict = app.config.globalProperties.$user.district,
            currentGradeLevel = app.config.globalProperties.store.state.showingGradeLevel
        }) {

            // single school mode
            if (!multiSchool) {
                return source.database == databases.main ?
                    query(
                        collection(...getPath(), source.database),
                        where('currentGradeLevel', '==', currentGradeLevel)
                    ):
                    query(
                        collection(...getPath(), source.database)
                    );
            }

            if (!userDistrict) {
                console.error('User has no district set up. Cannot view multi-school app');
                return;
            }

            // multischool mode: return records from ALL schools if we have "RISENetwork" as district
            if (userDistrict == 'RISENetwork') {
                return source.database == databases.main ?
                    query(
                        collectionGroup(db, source.database),
                        where('currentGradeLevel', '==', currentGradeLevel)
                    ):
                    query(
                        collectionGroup(db, source.database),
                    );
            }

            // multischool mode: return records from user's district
            return source.database == databases.main ?
                query(
                    collectionGroup(db, source.database),
                    where('schoolDistrict', '==', userDistrict),
                    where('currentGradeLevel', '==', currentGradeLevel)
                ):
                query(
                    collectionGroup(db, source.database),
                );
        },
        subscribe() {
            this.dataSources.forEach(source => {

                if (!app.config.globalProperties.config.databases.includes(source.key)) return;

                const q = this.getQuery({source});

                this[source.unsubscribeFn] = onSnapshot(q, querySnapshot => {
                    const result = [];
                    querySnapshot.forEach(doc => {
                        const myRecord = doc.data();
                        myRecord.docID = doc.id;
                        result.push(myRecord);
                    });
                    app.config.globalProperties.store[source.setDataFn](result);
                });    
            });
        },
        unsubscribeAll() {
            if (!app.config.globalProperties.store.state.hasLaunched) return;
            this.dataSources.forEach(source => {
                if (this[source.unsubscribeFn] !== undefined) {
                    this[source.unsubscribeFn]();
                }
            });
        },
        messages: {
            add (message) {
                if (validate(schemas.notes, message)) {
                    addDoc(collection(...getPath(), databases.notes), message);
                }
            },
            delete (message) {
                deleteDoc(doc(...getPath(), databases.notes, message.docID));
            },
            update (id, message) {
                if (validate(schemas.notes, message)) {
                    setDoc(doc(...getPath(), databases.notes, id), message);
                }
            }
        },
        tags: {
            add (tag) {
                addDoc(collection(...getPath(), databases.tags), tag);
            },
            delete (tag) {
                deleteDoc(doc(...getPath(), databases.tags, tag.docID));
            },
            update (id, tag) {
                setDoc(doc(...getPath(), databases.tags, id), tag);
            }
        },
        studentData: {
            update (id, record, field) {
                if (validate(schemas.main, record)) {
                    updateDoc(doc(...getPath(), databases.main, id), {[field]: record[field]});
                }
            },
            set (id, record) {
                if (validate(schemas.main, record)) {
                    setDoc(doc(...getPath(), databases.main, id), record);
                }
            }
        },
        user: {
            updatePreference (key, newValue) {
                app.config.globalProperties.$user.preferences[key] = newValue;

                updateDoc(
                    doc(db, databases.users, app.config.globalProperties.$user.uid), 
                    { preferences: app.config.globalProperties.$user.preferences }
                );
            },
            async getMyself(uid) {
                const docRef = doc(db, databases.users, uid);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) console.error('No user document found!');

                return docSnap.data();
            },
        },
        schools: {
            async getMetaData() {                
                const docSnap = await getDoc(doc(db, currentEnvironment.schoolCollection, 'school_metadata'));
                
                if (docSnap.exists()) {
                    return docSnap.data();
                } else {
                    console.error('No school meta data found');
                    return [];
                }
            }
        }
    };
};

// User Tracking

// events: {
//     add (event) {

        // Used for usage tracking.

        // const defaultEvent = {
        //     uid: app.config.globalProperties.$user.uid,
        //     school: app.config.globalProperties.store.state.chosenSchool,
        //     datetime: Date.parse(new Date()),
        //     app: app.config.globalProperties.store.state.currentApp
        // };

        // const eventToAdd = {...defaultEvent, ...event};

        // if (app.config.globalProperties.config.trackUsage) {
        //     addDoc(collection(...getPath(), databases.events), eventToAdd);
        // }
//     }
// },

// User management area.
// add (user) {
//     if (validate(schemas.user, user)) {
//         addDoc(collection(db, databases.users), user);
//     }
// },
// update (id, record, field) {
//     if (validate(schemas.user, record)) {
//         updateDoc(
//             doc(db, databases.users, id), 
//             { [field]: record[field] }
//         );
//     }
// },
// async getAll() {
//     const result = [];
//     const userSnapshot = await getDocs(collection(db, databases.users));
//     userSnapshot.forEach(doc => {
//         const myRecord = doc.data();
//         myRecord.docID = doc.id;                
//         result.push(myRecord);
//     });
//     return result;
// },