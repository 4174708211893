<template>
<div class="modal-container">      
    <div :class="dynamicClass" class="modal">
        <div class="modal-content">
            <div class="modal-header">
                <span @click="hide" class="close">&times;</span>
                <h2>{{ title }}</h2>
            </div>
            <div class="modal-body">
                <p v-if="instructions">{{ instructions }}</p>
                <slot></slot>
                <div v-if="showButtons">
                    <CustomButton data-test="modal-success-button" class="btn" @click="success" :buttonText="acceptText" />
                    <CustomButton data-test="modal-cancel-button" class="btn" @click="hide" :buttonText="cancelText" />
                </div>
                <div v-else class="bottom-padding-area">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CustomButton from '@/components/customButton.vue';
export default {
    name: 'Modal',
    props: {
        title: String,
        instructions: String,
        showButtons: {
            type: Boolean,
            required: false,
            default: true
        },
        acceptButtonText: {
            type: String,
            required: false,
            default: 'OK'
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: 'Cancel'
        }
    },
    data() {
        return {
            myValue: '',
            dynamicClass: 'hidden',
            acceptText: this.acceptButtonText,
            cancelText: this.cancelButtonText
        }
    },
    methods: {
        show() {
            this.dynamicClass = 'visible';
        },
        hide() {
            this.dynamicClass = 'hidden';
        },
        success() {
            this.$emit('success');
            this.hide();
        }
    },
    components: {
        CustomButton
    }
}
</script>

<style lang="scss">
@import '../assets/styles/colors';

.btn {
    margin-right: 20px;
    margin-bottom: 20px;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.modal {
    font-family: 'Lato', sans-serif;
    position: fixed; 
    z-index: 30; 
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--color-contrast-full);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    position: relative;
    background-color: var(--color-contrast-lower);
    margin: auto;
    padding: 0;
    border: 1px solid var(--color-contrast-higher);
    width: 450px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 10px;
    .modal-header {
        padding: 10px 16px;
        background-color: var(--color-success);
        color: var(--color-bg);
        border-radius: 10px 10px 0px 0px;
        h2 {
            margin: 0;
        }
    }
    .modal-body {
        input {
            // display: block;
            margin: 5px 0px 12px;
        }
        padding: 0px 16px 0px;
        label {
            padding-left: 10px;
        }
    }
}

.bottom-padding-area {
    margin-bottom: 20px;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.close {
    color: var(--color-bg);
    float: right;
    font-size: 2.4rem;
    font-weight: bold;
    &:hover, &:focus {
        color: var(--color-contrast-medium);
        text-decoration: none;
        cursor: pointer;
    }
}

</style>