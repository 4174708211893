import { defaultViews } from '@/apps/glt/views.js';
import { matrixConfig } from '@/apps/glt/matrix.js';
import { fields, fieldGroups } from '@/apps/glt/fields.js';

export const apps = [
    {
        key: 'GLT',
        displayName: 'Grade Level Tool',
        description: 'The Grade Level Tool helps Grade Level Teams track the progress of students in Grades 9-12.',
        icon: 'trending_up',
        applets: ['tracker', 'profile', 'analytics'],
        launchRoute: 'tracker',
        trackerConfig: {
            showViews: true,
            defaultView: 'Default',
            defaultViews,
            matrixConfig,
        },
        analyticsConfig: {
            rowGroup: 'englishLearner',
            columnGroup: 'gender',
            redirectDefaultFields: [
                '_studentName',
            ]
        },
        profileConfig: {
            showCourses: true
        },
        fields, 
        fieldGroups, 
        chatAvailable: false,
        availableGradeLevels: [9, 10, 11, 12],
        databases: ['main'],
        defaultGradeLevel: 9,
        multiSchool: false,
        trackUsage: false
    }
]