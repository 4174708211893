<template>
    <div class="multiple-choice">
        <div class="individual-choice" v-for="(item, index) in options" :key="index">
            <input v-model="checkedState[index]" type="checkbox" :id="'multiple-choice-' + formKey + index">
            <label :for="'multiple-choice-'+ formKey + index">{{ item }}</label>
        </div>
        <span class="hide-me">{{ newValue }}</span>
    </div>
</template>

<script>
export default {
    props: {
        options: Array,
        modelValue: String,
        formKey: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            checkedState: this.getCheckedState()
        }
    },
    methods: {
        getCheckedState() {
            return this.modelValue ?
                this.options.map(element => this.modelValue.split(',').includes(element)):
                this.options.map(element => false)
        }
    },
    watch: {
        modelValue: function () {
            this.checkedState = this.getCheckedState();
        }
    },
    computed: {
        newValue: function () {
            if (!this.options) return [];
            const toEmit = this.options.filter((element, i) => this.checkedState[i]).join(',');
            this.$emit('update:modelValue', toEmit);
        }
    },
}
</script>

<style lang="scss">
    @import '../assets/styles/colors.scss';
    .individual-choice {
        height: 2.3rem;
    }

    .multiple-choice {
        margin-bottom: 2rem;
    }

    .hide-me {
        opacity: 0;
        position: absolute;
    }
</style>